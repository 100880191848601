import {
  DateTimeValidationError,
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { formatToISODate, isValidDate } from "common/utils";
import { FieldUIComponent } from "core/api";
import dayjs from "dayjs";
import { FieldError, FieldValues, UseFormTrigger } from "react-hook-form";

export interface FormDatePickerEvent {
  /** Date object */
  $d?: Date;
}

export interface FormDatePickerValue {
  /** Form date picker event */
  event: FormDatePickerEvent;
  /** Keyboard input value */
  keyboardInputValue: string | undefined;
}

/**
 * Gets a date validator function based on input format
 * @param inputFormat Date input format
 * @param dateValidationError Validation error message for date value
 * @param required If field is required
 * @param requiredValidationError Validation error message for required
 * @returns Date validator function
 */
export function getKeyboardInputDateValidator(
  inputFormat: string,
  dateValidationError: string,
  required: boolean | undefined,
  requiredValidationError: string
) {
  return (value: FormDatePickerValue | Date | string) => {
    if (value as FormDatePickerValue) {
      const formDatePickerValue = (value as FormDatePickerValue).event?.$d;
      if (formDatePickerValue && !dayjs(formDatePickerValue).isValid()) {
        return dateValidationError;
      }
    }

    if (typeof value === "string" || value instanceof Date) {
      return true;
    }
    if (value.keyboardInputValue) {
      return (
        isValidDate(value.keyboardInputValue, inputFormat, true) ||
        dateValidationError
      );
    }
    if (required && !value.event) {
      return requiredValidationError;
    }
    return true;
  };
}

/**
 * Gets date picker onChange function
 * @param onChange Form onChange function
 * @returns Picker onChange function
 */
export function getFormDatePickerOnChange(
  onChange: (value: FormDatePickerValue) => void
) {
  return (
    event: any,
    context: PickerChangeHandlerContext<
      DateValidationError | DateTimeValidationError
    >
  ) => {
    onChange({
      event,
      keyboardInputValue: "",
    });
  };
}

/**
 * Formats form date picker value to ISO string
 * @param value Form date picker value
 * @returns Formatted date value
 */
export function formatFormDatePickerValue(
  value: FormDatePickerValue | string,
  uiComponent?: FieldUIComponent
): string {
  const _uiComponent =
    uiComponent !== undefined ? uiComponent : FieldUIComponent.DateTime;
  if (typeof value === "string") {
    return value;
  }
  if (isValidDate(value.event?.$d)) {
    if (_uiComponent === FieldUIComponent.Date) {
      // Return only the date, without any time nor timezone
      return value.event.$d ? formatToISODate(value.event.$d) : "";
    } else {
      // Return a full ISO date with date, time and timezone
      return value.event.$d?.toISOString() ?? "";
    }
  }
  return "";
}

/**
 * Inserts input hint in error message
 * @param error Field error
 * @param helperText Field helper text
 * @param hint Date format error input hint
 * @returns Error message with inserted hint, or helper text
 */
export function insertDateErrorHint(
  error: FieldError | undefined,
  helperText: string | undefined,
  hint: string | undefined
) {
  return error?.message?.replace("{{hint}}", hint ?? "") ?? helperText;
}

/**
 * Triggers validation for the field if `value` and `name` are defined.
 * @param name - The name of the field to trigger validation for.
 * @param value - The value for the field.
 * @param trigger - Function that triggers field validation
 */
export function triggerFieldEvaluation(
  name: string | undefined,
  value: any,
  trigger: UseFormTrigger<FieldValues>
) {
  if (name && value) {
    trigger(name);
  }
}
