import {
  EntityData,
  FieldGridConfig,
  FlowStepComponent,
  ValidValue,
} from "core/api";
import { TableSelectionVariant } from "core/components";

interface BaseFlowStep<
  TUIComponent extends FlowStepComponent = FlowStepComponent,
  TConfig extends object = {}
> {
  /** Flow step instance id */
  id: string;
  /** Static flow step definition id */
  stepId: number;
  /** Unique GUID used for flow added/injected the step.
  flowUID: string;
  /** UI component */
  uiComponent: TUIComponent;
  /** Step icon */
  icon: string;
  /** Flow step config */
  config: TConfig;
  /** Flow step name */
  name: string;
  /** Flow step group name */
  group?: string;
  /** Flow step group indicator  0 is for the initial flow */
  groupNo: number;

  /** Flow step input hint */
  hint: string;
  /** Flow step description */
  description: string;
  /** If step should be evaluated on enter */
  evalOnEnter: boolean;
  /** If step should be evaluated on leave */
  evalOnLeave: boolean;
  /** If evaluation can inject steps */
  injectSteps: boolean;
  /** If step is injected */
  injected: boolean;
  /** If finishing the action is allowed */
  finishAllowed: boolean;
  /** If the step contains child steps */
  childStep: boolean;
}

export interface FlowStepAction {
  /** Action id */
  id: string;
  /** Action name */
  name: string;
  /** Action description */
  description: string;
}

export type FieldsFlowStep = BaseFlowStep<"Fields", FieldGridConfig>;

export type AddressFieldsFlowStep = BaseFlowStep<
  "AddressFields",
  {
    /** Copy from address type id */
    copyFromAddressTypeId: number;
  } & FieldGridConfig
>;

export type ActionListFlowStep = BaseFlowStep<
  "ActionList",
  { actions: FlowStepAction[] }
>;

export type TaggedTableData = EntityData<
  {
    /** Filter tags */
    tags?: string[];
  } & Record<string, any>
>;

export type TableFlowStep = BaseFlowStep<
  "Table",
  {
    /** Table id */
    tableId: number;
    /** If selection is required */
    required: boolean;
    /** Single or multi selection */
    selectionVariant: TableSelectionVariant;
    /** Show search if there are more than x table items. Defaults to -1. */
    showSearchIfMoreThan?: number;
    /** Limit for number of tag checkboxes. Switches to multi select if exceeded. */
    tagCheckboxLimit?: number;
    /** Table items. Used instead of fetching data with API url in table config if provided. */
    tableItems?: TaggedTableData[];
    /** Overrides apiUrl from table config */
    apiUrl?: string;
  }
>;

export type UpgradeDowngradeFlowStep = BaseFlowStep<"UpgradeDowngrade", {}>;

export type SelectProductFlowStep = BaseFlowStep<"SelectProduct", {}>;

/** Config type for the step */
export type FindInventoryFlowStep = BaseFlowStep<
  "FindInventory",
  {
    /** Set by server and needs to be there for server   */
    propertyTemplateId: number;
    /** All possible locations */
    locations: ValidValue[];
    /** All possible statues */
    statuses: ValidValue[];
    /** Articles available to product characteristic (if any) */
    articles: ValidValue[];

    /** The table step component config used to display the inventories */
    tableStepConfig: TableFlowStep;
  }
>;

export type FindNetworkElementFlowStep = BaseFlowStep<
  "FindNetworkElement",
  {
    /** Set by server and needs to be there for server   */
    propertyTemplateId: number;
    /** All possible Network Element Types */
    networkElementTypes: ValidValue[];

    /** The table step component config used to display the inventories */
    tableStepConfig: TableFlowStep;
  }
>;

export type SelectStepFlowStep = BaseFlowStep<
  "SelectStep",
  {
    addEmptySelection: boolean;
    steps: FlowStep[];
    /** If sorting the steps in the list by name in alphabetical order. Default is false */
    sortInAlphabeticalOrder: boolean;
  }
>;

export type OrderAttributesFlowStep = BaseFlowStep<
  "OrderAttributes",
  {
    allowedToApprove: boolean;
  }
>;

/**
 * Config data for the SetReseller step.
 */
export type SetResellerFlowStep = BaseFlowStep<
  "SetReseller",
  {
    // Resellers possible to select
    resellers: ValidValue[];

    // If the agent field should be enabled or not.
    enableAgentField: boolean;

    // Label for reseller
    resellerLabel: string;

    // Label for agent
    agentLabel: string;
  }
>;

export type RepaymentFlowStep = BaseFlowStep<"Repayment", {}>;

export type ChangeEntityStatusFlowStep = BaseFlowStep<
  "ChangeEntityStatus",
  {
    allowedToApprove: boolean;
    allowedToBackDate: boolean;
    simpleMode: false;
  }
>;

/**
 * Helper function to convert any FlowStep type that extends ChangeEntityStatusFlowStep
 * into a ChangeEntityStatusFlowStep.
 * Needed because of Typescript type checking.
 *
 * @param step any FlowStep type that extends ChangeEntityStatusFlowStep
 * @returns a ChangeEntityStatusFlowStep that contains the same properties as **step**
 */
export function extractChangeEntityStatusFlowStep(
  step: ChangeCustomerStatusFlowStep | ChangeProductStatusFlowStep
): ChangeEntityStatusFlowStep {
  return {
    ...step,
    uiComponent: "ChangeEntityStatus",
  } as ChangeEntityStatusFlowStep;
}

export type ChangeCustomerStatusFlowStep = BaseFlowStep<
  "ChangeCustomerStatus",
  // ChangeCustomerStatusFlowStep inherits the config properties of ChangeEntityStatusFlowStep
  ChangeEntityStatusFlowStep["config"]
>;

export type ChangeProductStatusFlowStep = BaseFlowStep<
  "ChangeProductStatus",
  // ChangeProductStatusFlowStep inherits the config properties of ChangeEntityStatusFlowStep
  ChangeEntityStatusFlowStep["config"]
>;

export type BindsFlowStep = BaseFlowStep<
  "Binds",
  {
    allowedToChangeAdjustmentDate: boolean;
    allowedToOverrideBinds: boolean;
    allowedToSupressFees: boolean;
  }
>;

export type MissingConfigFlowStep = BaseFlowStep<"MissingConfig", {}>;

export type LogisticsFlowStep = BaseFlowStep<
  "Logistics",
  {
    articles: ValidValue[];
  }
>;

export type CreditCheckFlowStep = BaseFlowStep<"CreditCheck", {}>;

export type CreditInvoiceFlowStep = BaseFlowStep<
  "CreditInvoice",
  {
    tableId: number;
  }
>;

export type CreatePaymentFlowStep = BaseFlowStep<"CreatePayment", {}>;
export type PayInvoiceFlowStep = BaseFlowStep<"PayInvoice", {}>;
export type EditInventoryFlowStep = BaseFlowStep<"EditInventory", {}>;
export type ChangeDueDateFlowStep = BaseFlowStep<"ChangeDueDate", {}>;
export type ConfigureInvoiceDunningFlowStepConfig = BaseFlowStep<
  "ConfigureInvoiceDunning",
  {
    block: boolean;
    dunningMeasure: string;
  }
>;
export type EditCustomerNoteFlowStep = BaseFlowStep<"EditCustomerNote", {}>;

export type AdjustmentPropertiesFlowStep = BaseFlowStep<
  "AdjustmentProperties",
  {}
>;

export type ProductPartsConfigurationFlowStep = BaseFlowStep<
  "ProductPartsConfiguration",
  {}
>;

export type AmountWithVATFlowStep = BaseFlowStep<
  "AmountWithVAT",
  {
    allowZeroAmount: boolean;
  }
>;

export type AdjustmentAmountFlowStep = BaseFlowStep<
  "AdjustmentAmount",
  // AdjustmentAmountFlowStep inherits the config properties of AmountWithVATFlowStep
  AmountWithVATFlowStep["config"]
>;

/**
 * Data structure used to configure a Decision component.
 *
 * @remarks
 * - includeEmptyChoice (boolean): Set to true if the decision choices should contain a default 'empty' choice.
 *   If selectionRequired is also true, it forces the user to select on of the
 *   non-empty decisions, i.e. one of the decisions that are defined in DecisionFlowData.
 * - selectionRequired (boolean) : Set to true if the user must select a non-empty decision.
 *
 * @see {@link DecisionFlowData} for the data structure used by the Decision component.
 */
export type DecisionFlowStep = BaseFlowStep<
  "Decision",
  {
    /**
     *
     *
     * @remarks
     * If selectionRequired is also true, it forces the user to select on of the
     * non-empty decisions, i.e. one of the decisions that are defined in DecisionFlowData.
     *
     * @see DecisionFlowData
     */
    includeEmptyChoice: boolean;
    /**
     * Set to true if the user must select a non-empty decision.
     */
    selectionRequired: boolean;
  }
>;

export type TransferProductFlowStep = BaseFlowStep<"TransferProduct", {}>;

export type WebSetupSaveAsFlowStep = BaseFlowStep<"WebSetupSaveAs", {}>;

export type DefaultFlowStep = BaseFlowStep<
  Exclude<
    FlowStepComponent,
    | "ActionList"
    | "AddressFields"
    | "AdjustmentAmount"
    | "AdjustmentProperties"
    | "AmountWithVAT"
    | "Binds"
    | "ChangeCustomerStatus"
    | "ChangeDueDate"
    | "ChangeEntityStatus"
    | "ChangeProductStatus"
    | "CreatePayment"
    | "CreditCheck"
    | "CreditInvoice"
    | "ConfigureInvoiceDunning"
    | "EditCustomerNote"
    | "EditInventory"
    | "Decision"
    | "EditInventory"
    | "Fields"
    | "FindInventory"
    | "FindNetworkElement"
    | "Logistics"
    | "MissingConfig"
    | "OrderAttributes"
    | "PayInvoice"
    | "UpgradeDowngrade"
    | "ProductPartsConfiguration"
    | "Repayment"
    | "SelectStep"
    | "SetReseller"
    | "SelectProduct"
    | "Table"
    | "TransferProduct"
    | "WebSetupSaveAs"
  >
>;

export type FlowStep =
  | ActionListFlowStep
  | AddressFieldsFlowStep
  | AdjustmentAmountFlowStep
  | AdjustmentPropertiesFlowStep
  | AmountWithVATFlowStep
  | BindsFlowStep
  | CreditCheckFlowStep
  | CreditInvoiceFlowStep
  | CreatePaymentFlowStep
  | ChangeCustomerStatusFlowStep
  | EditCustomerNoteFlowStep
  | ChangeDueDateFlowStep
  | ChangeEntityStatusFlowStep
  | ChangeProductStatusFlowStep
  | ConfigureInvoiceDunningFlowStepConfig
  | DecisionFlowStep
  | DefaultFlowStep
  | EditInventoryFlowStep
  | FieldsFlowStep
  | FindInventoryFlowStep
  | FindNetworkElementFlowStep
  | LogisticsFlowStep
  | MissingConfigFlowStep
  | OrderAttributesFlowStep
  | PayInvoiceFlowStep
  | UpgradeDowngradeFlowStep
  | ProductPartsConfigurationFlowStep
  | RepaymentFlowStep
  | SelectStepFlowStep
  | SetResellerFlowStep
  | SelectProductFlowStep
  | TableFlowStep
  | TransferProductFlowStep
  | WebSetupSaveAsFlowStep;
