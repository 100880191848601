import { EntityType } from "core/api";
import { useTranslation } from "react-i18next";

/**
 * Provides a function for getting the title of the entity type, in singular or plural
 * @param entityType Entity type
 * @param count The number of times the entity type is used
 * @returns Returns entity type name in singular or plural
 */

export function useEntityTitle() {
  const { t } = useTranslation(["common"]);

  const getEntityTitle = (entityType: EntityType, count: number): string => {
    switch (entityType) {
      case EntityType.ADDRESS:
        return t("common:entityTypeName.address", { count });
      case EntityType.CUSTOMER:
        return t("common:entityTypeName.customer", { count });
      case EntityType.INVOICE_ACCOUNT:
        return t("common:entityTypeName.invoiceaccount", { count });
      case EntityType.DOCUMENT:
        return t("common:entityTypeName.document", { count });
      case EntityType.INVENTORY:
        return t("common:entityTypeName.inventory", { count });
      case EntityType.INVOICE:
        return t("common:entityTypeName.invoice", { count });
      case EntityType.MESSAGE:
        return t("common:entityTypeName.message", { count });
      case EntityType.NOTE:
        return t("common:entityTypeName.note", { count });
      case EntityType.NP_CASE:
        return t("common:entityTypeName.npcase", { count });
      case EntityType.PAYMENT:
        return t("common:entityTypeName.payment", { count });
      case EntityType.PRODUCT:
        return t("common:entityTypeName.product", { count });
      case EntityType.PRODUCT_OFFERING:
        return t("common:entityTypeName.productOffering", { count });
      case EntityType.REPAYMENT:
        return t("common:entityTypeName.repayment", { count });
      case EntityType.TASK:
        return t("common:entityTypeName.task", { count });
      case EntityType.TASK_LIST:
        return t("common:entityTypeName.tasklist", { count });
      case EntityType.USER:
        return t("common:entityTypeName.user", { count });
      case EntityType.WEB_APPLICATION:
        return t("common:entityTypeName.webApplication", { count });
      case EntityType.ADJUSTMENT:
        return t("common:entityTypeName.adjustment", { count });
      case EntityType.SUBSCRIPTION:
        return t("common:entityTypeName.subscription", { count });
      case EntityType.ACCOUNT:
        return t("common:entityTypeName.account", { count });
      case EntityType.NRDB_CLIENT:
        return t("common:entityTypeName.nrdbClient", { count });
      case EntityType.NRDB_CONFIG:
        return t("common:entityTypeName.nrdbConfig", { count });
      case EntityType.NRDB_MESSAGE:
        return t("common:entityTypeName.nrdbMessage", { count });
      case EntityType.NRDB_NUMBER:
        return t("common:entityTypeName.nrdbNumber", { count });
      case EntityType.NRDB_PROXY:
        return t("common:entityTypeName.nrdbProxy", { count });
      case EntityType.ORDER:
        return t("common:entityTypeName.order", { count });
      case EntityType.WEB_ACTION:
        return t("common:entityTypeName.webAction", { count });
      case EntityType.WEB_WIDGET:
        return t("common:entityTypeName.webWidget", { count });
      case EntityType.WEB_APP_CONFIG:
        return t("common:entityTypeName.webAppConfig", { count });
      case EntityType.WEB_SCREEN:
        return t("common:entityTypeName.webScreen", { count });
      case EntityType.WEB_VIEW:
        return t("common:entityTypeName.webView", { count });
      case EntityType.WEB_TABLE:
        return t("common:entityTypeName.webTable", { count });
      case EntityType.WEB_TABLE_OPTION:
        return t("common:entityTypeName.webTableOption", { count });
      case EntityType.WEB_TABLE_FIELD_DEFINITION:
        return t("common:entityTypeName.webTableFieldDefinition", { count });
      case EntityType.FLOW:
        return t("common:entityTypeName.webFlow", { count });
      case EntityType.FLOW_STEP_DEFINITION:
        return t("common:entityTypeName.webStep", { count });
      case EntityType.WEB_NAVIGATION_GROUP:
        return t("common:entityTypeName.webNavigationGroup", { count });
      case EntityType.WEB_SEARCH_FILTER:
        return t("common:entityTypeName.webSearchFilter", { count });
      default:
        return t("common:unknownEntity");
    }
  };

  return { getEntityTitle };
}
