export default {
  urlNotFound: "URL finns inte",
  notFoundOrNotAvailable:
    "Källan du förfrågade hittas inte eller finns inte längre",
  yourApplications: "Dina Applikationer",
  homePage: "Appar",
  applications: "Dina Appar",
  customerCare: "Kundtjänst",
  printerQueue: "Printerköer",
  loading: "Laddar...",
  loadingApp: "Laddar in webbapplikationen...",
  logout: "Logga ut",
  rowsperpage: "Rader per sida",
  switchTo: "Byt till",
  yourChangesAreSaved: "Ändringarna har sparats.",
  filters: "Filter",
  configuration: "Konfiguration",
  customerGroup: "Operatör",
  help: "Hjälp",
  notifications: "Notifikationer",
  dashboard: "Översikt",
  documentation: "Dokumentation",
  downloadingDocument: "Hämtar dokument...",
  loadingDocument: "Laddar in dokument...",
  noDocumentAvailable:
    "Det önskade dokumentet har antingen begränsad åtkomst eller är otillgängligt just nu",
  nextPage: "Next page",
  previousPage: "Previous page",
  downloadingFile: "Hämtar {{fileName}}",
  downloadFile: "Ladda ner {{fileName}}",
  fileName: "Filnamn",
  about: "Om ...",
  version: "Version: {{version}}",
  errorNoIdentityProvider:
    "Det gick inte att hämta någon identitetsleverantör från databasen.",
  name: "Namn",
  customerName: "Kundnamn",
  status: "Status",
  address: "Adress",
  invoices: "Fakturor",
  inventories: "Inventarier",
  type: "Typ",
  products: "Produkter",
  showHideButton: {
    show: "Expandera komponenten",
    hide: "Minimera komponenten",
  },
  bookmarks: {
    noFoundBookmarks: "Det finns inga {{entityType}}-bokmärken",
    noFoundEntityBookmarks: "Det finns inga bokmärken",
    addToBookmarks: "Lägg till bokmärken",
    removeFromBookmarks: "Ta bort från bokmärken",
  },
  entityTypeName: {
    account_one: "Konto",
    account_other: "Konton",
    address_one: "Adress",
    address_other: "Adresser",
    adjustment_one: "Justering",
    adjustment_other: "Justeringar",
    webApplication_one: "Applikation",
    webApplication_other: "Applikationer",
    customer_one: "Kund",
    customer_other: "Kunder",
    document_one: "Dokument",
    document_other: "Dokument",
    invoice_one: "Faktura",
    invoice_other: "Fakturor",
    inventory_one: "Inventarie",
    invoiceaccount_one: "Fakturakonto",
    invoiceaccount_other: "Fakturakonton",

    inventory_other: "Inventarier",
    message_one: "Meddelande",
    message_other: "Meddelanden",
    note_one: "Anteckning",
    note_other: "Anteckningar",
    npcase_one: "Porterings-Case",
    npcase_other: "Porterings-Case",
    payment_one: "Betalning",
    payment_other: "Betalningar",
    product_one: "Produkt",
    product_other: "Produkter",
    productOffering_one: "Produkterbjudande",
    productOffering_other: "Produkterbjudanden",
    repayment_one: "Återbetalning",
    repayment_other: "Återbetalningar",
    subscription_one: "Abonnemang",
    subscription_other: "Abonnemang",
    task_one: "Ärende",
    task_other: "Ärenden",
    tasklist_one: "Ärendelista",
    tasklist_other: "Ärendelistor",
    user_one: "Användare",
    user_other: "Användare",
    nrdbClient_one: "Klient",
    nrdbClient_other: "Klienter",
    nrdbConfig_one: "Konfiguration",
    nrdbConfig_other: "Konfigurationer",
    nrdbMessage_one: "Meddelande",
    nrdbMessage_other: "Meddelanden",
    nrdbNumber_one: "Telefonnummer",
    nrdbNumber_other: "Telefonnummer",
    nrdbProxy_one: "Proxy",
    nrdbProxy_other: "Proxys",
    order_one: "Order",
    order_other: "Ordrar",
    webWidget_one: "Widget",
    webWidget_other: "Widgets",
    webTable_one: "Tabell",
    webTable_other: "Tabeller",
    webTableOption_one: "Tabellalternativ",
    webTableOption_other: "Tabellalternativ",
    webTableFieldDefinition_one: "Tabellkolumn",
    webTableFieldDefinition_other: "Tabellkolumner",
    webView_one: "Vy",
    webView_other: "Vyer",
    webScreen_one: "Skärm",
    webScreen_other: "Skärmar",
    webFlow_one: "Flöde",
    webFlow_other: "Flöden",
    webStep_one: "Steg",
    webStep_other: "Steg",
    webAppConfig_one: "Appkonfiguration",
    webAppConfig_other: "Appkonfigurationer",
    webNavigationGroup_one: "Navigeringsgrupp",
    webNavigationGroup_other: "Navigeringsgrupper",
    webSearchFilter_one: "Sökfilter",
    webSearchFilter_other: "Sökfilter",
    webAction_one: "Action",
    webAction_other: "Actions",
  },
  dateGroupTitle: {
    today: "Idag",
    last_week: "Under den senaste veckan",
    last_month: "Under den senaste månaden",
    earlier: "Tidigare",
  },
  sideBar: {
    show: "Visa",
    hide: "Dölj",
    showTooltip: "Visa navigationsmenyn",
    hideTooltip: "Dölj navigationsmenyn",
  },
  loggedOut: {
    message: "Du har blivit utloggad",
    gotoLogin: "Till loginsidan",
  },
  editCustomerNote: {
    noteText: "Notering",
    status: "Kundens humör",
  },
  properties: "Egenskaper",
  noAvailableData: "Ingen tillgänglig data",
  noValue: "<inget värde>",
  noValueSelected: "<Ingen vald>",
  deleteSelected: "Ta bort valda objekt?",
  copyFrom: "Kopiera från",
  buttons: {
    accept: "Acceptera",
    cancel: "Avbryt",
    continue: "Fortsätt",
    clear: "Rensa",
    close: "Stäng",
    copy: "Kopiera",
    copyFrom: "Kopiera från {{addressType}} adress",
    create: "Skapa",
    decline: "Avböj",
    delete: "Ta Bort",
    dismiss: "Ångra",
    download: "Ladda ner",
    ok: "Ok",
    print: "Skriv ut",
    refreshPageData: "Uppdatera siddata",
    reset: "Rensa",
    save: "Spara",
    update: "Uppdatera",
    search: "Sök",
    undo: "Ångra",
    moreDetails: "Mer information",
    showAll: "Visa alla",
    showAllHiddenAfter: "resultat for {{search}} i {{entityType}}",
    showCountMore: "Visa {{count}} fler",
    showPDF: "Visa PDF",
    preview: "Förhandsvisa",
    previewHiddenAfter: "nästa faktura",
    next: "Nästa",
    back: "Tillbaka",
    finish: "Slutför",
    generateGUID: "Generera GUID",
    clearGUID: "Ta bort GUID",
    generatePINCode: "Generera PIN-kod",
    clearPINCode: "Ta bort PIN-kod",
    toggleVisibility: "Växla lösenordssynlighet",
    currentUserAccount: "Konto för den nuvarande användaren",
    accountMenu: "Kontomeny",
    applicationSwitcher: "Applikationsbytare",
    backTo: "Tillbaka till föregående {{entityType}}",
    backToTooltip: "Tillbaka till",
  },
  forms: {
    fieldIsMandatory: "Fältet är obligatoriskt",
    mandatory: "{{label}} (obligatoriskt)",
    minLength: "Fältet kräver minst {{count}} tecken",
    maxLength: "Fältet kräver {{count}} tecken eller mindre",
    lengthBetween:
      "Fältet kräver mellan {{minLength}} och {{maxLength}} tecken",
    lengthExactly: "Fältet kräver exakt {{count}} tecken",
    pattern: "Fältet kräver formatet {{pattern}}",
    formatShortSocialSecNo: "ÅÅMMDD-XXXX",
    formatLongSocialSecNo: "ÅÅÅÅMMDD-XXXX",
    date: {
      dateTimeError: "Valt datum eller tid är ogiltigt (ledtråd: {{hint}})",
      dateError: "Valt datum är ogiltigt (ledtråd: {{hint}})",
      timeError: "Vald tid är ogiltig (ledtråd: {{hint}})",
    },
    tableSelectionIsMandatory: "En rad måste väljas",
    jsonField: "Fältet kräver ett korrekt JSON-format",
    translations: {
      hint: "Visa Översättningar",
      title: "Översättningar",
      contentText: "Skriv översättningarna för {{label}}",
    },
    iconSelector: {
      searchIcons: "Sök efter ikoner...",
      selectIcon: "Välj ikon",
      noIcon: "Ingen ikon",
      openIconSelector: "Öppna ikonväljaren",
      icon: "Ikon,",
    },
  },
  table: {
    rowActionMenu: {
      tooltip: "Action för aktuell rad",
      ariaLabel: "Actionmeny",
    },
    multiRowActionMenu: {
      tooltip: "Actions för valda rader",
      ariaLabel: "Actionmeny",
    },
    expandRow: "Expandera raden",
    defaultError: "Ett fel uppstod vid inläsning av tabellen",
  },
  cookieText:
    "Billiant Web kräver strikt nödvändiga cookies för att möjliggöra grundläggande webbplatsfunktionalitet som användarinloggning och kontohantering. Webbplatsen kan inte användas korrekt utan strikt nödvändiga cookies.",
  snackbarMessageOccurredMultipleTimes: "(förekom {{times}} gånger)",
  fetchingData: "Hämtar data",
  keyboardShortcuts: "Tangentbordsgenvägar",
  settings: "Inställningar",
  settingsMenu: {
    settingsMenu: "Meny för Inställningar",
    allowDnD: "Tillåt dra-och-släpp",
  },
  navigation: "Navigation",
  filter: {
    hideFilter: "Dölj filter",
    showFilter: "Visa filter",
  },
  searchFilter: {
    currency: "Valuta",
    selectCurrency: "Välj valuta",
    textFrom: "{{text}} (från)",
    textTo: "{{text}} (till)",
    fromValue: "Från {{value}}",
    toValue: "Till {{value}}",
    searchText: "Söktext",
    today: "Idag",
  },
  ofTotal: "av {{count}}",
  zoomIn: "Zooma in",
  zoomOut: "Zooma ut",
  approveOrders: "Godkänn ordrar",
  adjustmentDate: "Datum för justering",
  chargeType: "Typ av justering",
  generalLedgerInfo: "Bokföringskonto",
  taxBundleInfo: "Skattesatser",
  adjustmentDescription: "Beskrivning",
  adjustmentDescriptionHelper:
    "Välj eller ange en beskrivning som kommer att visas på fakturan.",
  decision: "Beslut",
  earliestInvoiceDate: "Tidigaste fakturadatum",
  enableImmediateInvoicing: "Omedelbar fakturering",
  inputAmountInclVat: "Ange belopp inklusive {{vatRate}} MOMS",
  amountExclVat: "Belopp (exklusive {{vatRate}} MOMS)",
  amountVat: "Mervärdesskatt (MOMS)",
  amountInclVat: "Belopp (inklusive {{vatRate}} MOMS)",
  amountsInclTax: "Visa/inmatade belopp inklusive MOMS",
  effectiveDate: "Ändringsdatum",
  overrideBinds: "Bryt bindningsregler",
  reason: "Orsak",
  supressFees: "Ignorera bindningsavgifter",
  categoryWidget: {
    noCategoriesFound: "Inga kategorier hittades",
    noCategoriesFoundHelper: "Denna kund har inga kategorier",
    missingScreenEntity:
      "Widgeten hittade ingen {{entityType}}-entitet i skärmen",
    missingScreenEntityHelper:
      "Widgeten hittade ingen {{entityType}}-entitet i skärmens lista med entiteter.",
  },
  historyWidget: {
    message: "Meddelande",
    oldValue: "Gammalt värde",
    newValue: "Nytt värde",
    orderId: "Order-ID",
    dateLastPost: "Datum för senaste ändringen {{date}}",
  },
  dayOfMonth: "dag i månad",
  days: "Dagar",
  amount: "Belopp",
  amountWithCurrency: "Belopp ({{currencyCode}})",
  current: "Aktuell",
  totalAmount: "Totalt {{amount}}",
  createdBy: "Skapad av: {{createdBy}}",
  scoreWidget: {
    score: "Poäng: {{score}}",
    noScore: "Inget poäng",
  },
  reminderXSent: "Påminnelse {{number}} skickad",
  titleIsMissing: "Titel saknas",
  descriptionIsMissing: "Beskrivning saknas",
  noChildWidget:
    "Ingen matchande widget hittades. Entity type: {{EntityType}},  entity type ID: {{number}}",
  noResultsFound: "Hittade inga poster",
  alerts_one: "{{count}} varning",
  alerts_other: "{{count}} varningar",
  unknownEntity: "Okänd entitet",
  breadcrumb: "Synlig sökväg",
  selectAll: "Välj alla",
  items_one: "{{count}} föremål",
  items_other: "{{count}} föremål",
  appNameLogo: "{{appName}} logotyp",
  yes: "Ja",
  no: "Nej",
  choose: "Välj",
  identifier: "Identifierare",
  score: "Poäng",
  check: "Kontrollera",
  creditScore: "Kreditpoäng",
  creditStatus: "Kreditstatus",
  creditRatingStatus: {
    notChecked: "Ej kontrollerad",
    creditOk: "Kreditvärdig",
    creditWarning: "Kreditvärdig med varning",
    creditNotOk: "Icke kreditvärdig",
  },
  noTaskEntity: "Det finns ingen entitet kopplat till ärendet",
  productOfferingOptions: {
    availableRenewalOptions:
      "Det finns {{optionsLength}} förnyelsealternativ tillgängliga.",
    noRenewalOptions:
      "Inga förnyelsealternativ finns tillgängliga. Produkten är inte aktiv eller har bindningsregler.",
    payWithCurrent: "Betala med nuvarande {{current}}",
    payWithMethod: "Betala med {{method}}",
    readAndApprove: "Jag har läst och godkänner",
    termsAndConditions: "villkoren.",
    payAndRenew: "Förnya och betala {{amountToPay}}",
  },
  description: "Beskrivning",
  applicablePrices: "Tillämpliga priser",
  orderList: {
    configError: {
      name: "Fel i webbkonfigurationen",
      description:
        "OrderListWidget kräver en TableConfig som innehåller ett enda TableOption för entitetstypen ORDER",
    },
  },
  orderCard: {
    completionDate: "Fullföljdes: ",
    orderDate: "Beställdes: ",
  },
  subject: "Ämne",
  messageText: "Meddelandetext",
  productLifeCycle: {
    periods: {
      bind: {
        label: "Bindningstid",
        description:
          "Produkten kan inte sägas upp före det tidigaste uppsägningsdatumet, såvida inte påföljder för avtalsbrott tillämpas.",
        before: "FÖRE bindnings-tiden",
        within: "INOM bindningstiden",
        after: "EFTER bindningstiden",
      },
      regret: {
        label: "Ångerperioden",
        description:
          "Produkten kan sägas upp med omedelbar verkan. Inga påföljder för överträdelse är tillämpliga.",
        within: "INOM ångerperioden",
      },
      notice: {
        label: "Uppsägningstid",
        description: {
          beforeLatestNoticeDate:
            "Produkten kan sägas upp vid bindningstidens slut förutsatt att uppsägning begärs INNAN sista uppsägningsdagen.",
          withAdjustment:
            "Produkten kan sägas upp vid den tidigaste uppsägningsdagen förutsatt att uppsägning begärs INNAN sista uppsägningsdagen.",
          withoutAdjustment:
            "Produkten kan sägas upp vid den tidigaste uppsägningsdagen förutsatt att uppsägning begärs IDAG.",
        },
        within: "EFTER den sista uppsägningsdagen inom bindningstiden",
      },
    },
    marks: {
      bindStart: "Bindnings-tidens början",
      bindEnd: "Bindnings-tidens slut",
      latestRegret: "Den sista ångerdagen",
      latestNotice: "Den sista uppsägnings-dagen",
      noticeDateWithinBind: "Den sista uppsägningsdagen inom bindningstiden",
      earliestTermination: "Tidigaste uppsägnings-dagen",
    },
    currentDateIs: "Aktuellt datum är ",
    today: "IDAG",
  },
  noItemSelected: "Inget objekt valt",
  noActionMatchingSelection: "Inga actions matchar det aktuella valet",

  webSetup: {
    newName: "Namn",
    newPath: "Sökväg",
    copyChildren: "Skapa kopior av",
    visibility: "Synlighet",
  },
} as const;
