import { Close, Translate } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormTextField,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "common/components";
import { TranslatedText } from "core/api/models/TranslatedText";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { combineSx } from "styles/theme";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export type FormTranslatableFieldProps = Omit<TextFieldProps, "variant"> &
  FormControlledFieldProps & {
    renderValue?: (value: any) => any;
    translations?: TranslatedText[];
  };

/**
 * Adds form handling for Translatable Textfield.
 *
 * @param translations List of TranslatedText with translations for the current field
 */
export const FormTranslatableField =
  withFormController<FormTranslatableFieldProps>(
    ({
      placeholder,
      defaultValue,
      fieldName,
      helperText,
      renderValue,
      renderState,
      translations,
      ...props
    }): ReactElement => {
      const { t } = useTranslation(["common"]);
      const {
        field: { value, ...field },
        fieldState: { error },
        isDisabled,
        gridColumn,
      } = renderState;

      const [open, setOpen] = useState(false);

      const openCloseDialog = () => {
        setOpen(!open);
      };

      let translationFields: JSX.Element[] = [];

      if (translations) {
        // ^^^^ needs to be here to make next line work
        for (let i = 0; i < translations.length; i++) {
          var trans = translations[i];
          // All textFields need to be a FormTextField to be able to belong to the form
          translationFields.push(
            <FormTextField
              key={i}
              fieldName={fieldName + "_" + trans.language}
              placeholder={""}
              defaultValue={trans.text}
              fullWidth
              margin="dense"
              {...props}
              sx={combineSx({ gridColumn }, props.sx)}
              required={false}
              label={trans.language}
            />
          );
        }
      }

      return (
        <>
          <TextField
            value={renderValue?.(value) ?? value ?? ""}
            fullWidth
            {...props}
            {...field}
            helperText={helperText}
            sx={combineSx({ gridColumn }, props.sx)}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={t("common:forms.translations.hint")}>
                      <IconButton size="small" onClick={openCloseDialog}>
                        <Translate />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              },
            }}
          />
          <Dialog open={open} onClose={openCloseDialog}>
            <IconButton
              onClick={openCloseDialog}
              sx={{ position: "absolute", top: "0", right: "0" }}
            >
              <Close />
            </IconButton>
            <DialogTitle>{t("common:forms.translations.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {/* {"Enter the optional translations for " + props.label} */}
                {t("common:forms.translations.contentText", {
                  label: props.label,
                })}
              </DialogContentText>
              {translationFields}
            </DialogContent>
            <DialogActions>
              <Button onClick={openCloseDialog}>
                <Typography>{t("common:buttons.ok")}</Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  );
