/**
 * This file contains the definitions of all the FlowData types.
 *
 * @remarks
 * The FlowData structures are used to transport data used by the Flow Steps
 * from the server (REST API) to the client (web application).
 * There should be one FlowData type for every Flow Step.
 * All the FlowData types extend the BaseFlowData type.
 *
 */
import {
  BaseField,
  CreditRating,
  EntityRef,
  EntityType,
  ProductOfferingOption,
  Severity,
  ValidValue,
} from "core/api";
import { CardinalityRow } from "../../CardinalityRow";
import { FlowStepAction } from "./FlowStep";
import { FlowStepComponent } from "./types";

/**
 * A step message holder.
 */
export interface StepMessage {
  /** The severity of the message. Usually at most one of error. Several warning/info may exist.*/
  severity: Severity;

  /** The message text */
  text: string;

  // Actually server info only
  persist: boolean;

  /** If disable the flow buttons */
  disableFlowButtons: boolean;
}

type BaseFlowData<
  TUIComponent extends FlowStepComponent,
  TData extends Record<string, any>
> = {
  /** UI component */
  uiComponent: TUIComponent;

  /**Optionally one or several messages to display for the step */
  messages?: StepMessage[];
} & TData;

export interface FlowDataField extends BaseField {
  /** Field data error message */
  error?: string;
}

export type FieldsFlowData = BaseFlowData<
  "Fields",
  {
    entityRef: EntityRef;
    fields: FlowDataField[];
  }
>;

/** Model data for the FindInventoryStep */
export type FindInventoryFlowData = BaseFlowData<
  "FindInventory",
  {
    /** Maximum number of inventory items displayed in the search results */
    maxInvItems: number;
    /** Set to true to prevent the user from changing the maximum number of
     * inventory items displayed in the search results */
    maxInvItemsReadOnly: boolean;
    /** Current selected article */
    articleId: number;
    /** Current selected location */
    locationId: number;
    /** Set to true to prevent the user from selecting a location
     * in the search filters */
    locationReadOnly: boolean;
    /** Current selected status */
    statusId: number;
    /** Set to true to prevent the user from selecting a status
     * in the search filters */
    statusReadOnly: boolean;
    /** The search text entered */
    searchText: string;
    /** The contained table step that displays the items */
    tableStep: TableFlowData;
  }
>;

/** Model data for the FindNetworkElementStep */
export type FindNetworkElementFlowData = BaseFlowData<
  "FindNetworkElement",
  {
    /** To limit the search result */
    maxTableItems: number;
    /** If the maxTableItems text box is enabled or not */
    maxTableItemsEnabled: boolean;
    /** Set to false to disable selection of the network element type */
    networkElementTypeSelectionEnabled: boolean;
    /** Default/Currently selected Network Element Type */
    networkElementTypeId: string;
    /** The search text entered */
    searchText: string;
    /** The contained table step that displays the items */
    tableStep: TableFlowData;
  }
>;

export type AddressFieldsFlowData = BaseFlowData<
  "AddressFields",
  {
    /**
     * (input) An array of ValidValues that represent the addresses from which the
     * user can copy from. It should contain the list of all the addresses
     * associated with the current customer.
     */
    customerAddresses: ValidValue[];
    /**
     * (input and output) The ID of the Address currently selected in the
     * 'Copy From' drop-down selection.
     */
    copyFromAddressId: number;
    /**
     * Copy from address type. Only set if the copyFromAddressTypeId is set.
     */
    copyFromAddressType: ValidValue;
  } & Omit<FieldsFlowData, "uiComponent">
>;

export type OrderAttributesFlowData = BaseFlowData<
  "OrderAttributes",
  {
    orderAttributes: {
      effectiveDate: string;
      applicationTag: null;
      approved: boolean;
      priority: string;
      holdReason: string;
      orderParentId: number;
      provisioningFlow: string;
      orderCollectionId: number;
      externalOrderTag: null;
      reasonCodes: [];
    };
  }
>;

export type RepaymentFlowData = BaseFlowData<
  "Repayment",
  {
    repaymentDate: string;
    repaymentAmount: number;
    paymentAmount: number;
    remainingAmount: number;
    paymentReference: string;
    currencyCode: string;
    message: string;
    approved: boolean;
    currencyId: number;
    allowedToApprove: boolean;
  }
>;

export type ChangeEntityStatusFlowData = BaseFlowData<
  "ChangeEntityStatus",
  {
    orderAttributes: {
      effectiveDate: string;
      applicationTag: null;
      approved: boolean;
      priority: string;
      holdReason: string;
      orderParentId: number;
      provisioningFlow: string;
      orderCollectionId: number;
      externalOrderTag: null;
      reasonCodes: [];
    };
    statusReasonId: number;
    applicableReasons: ValidValue[];
    lockedUntil?: string;
  }
>;

/**
 * Helper function to convert any FlowData type that extends ChangeEntityStatusFlowData
 * into a ChangeEntityStatusFlowData.
 * Needed because of Typescript type checking.
 *
 * @param data any FlowData type that extends ChangeEntityStatusFlowData
 * @returns a ChangeEntityStatusFlowData that contains the same properties as **data**
 */
export function extractChangeEntityStatusFlowData(
  data: ChangeCustomerStatusFlowData | ChangeProductStatusFlowData
): ChangeEntityStatusFlowData {
  return {
    ...data,
    uiComponent: "ChangeEntityStatus",
  } as ChangeEntityStatusFlowData;
}

export type ChangeCustomerStatusFlowData = BaseFlowData<
  "ChangeCustomerStatus",
  Omit<ChangeEntityStatusFlowData, "uiComponent">
>;

export type ChangeProductStatusFlowData = BaseFlowData<
  "ChangeProductStatus",
  Omit<ChangeEntityStatusFlowData, "uiComponent">
>;

/**
 * Model data for the SetReseller step. Data modeled with a field to make it easier and similar to an ordinary Fields step.
 */
export type SetResellerFlowData = BaseFlowData<
  "SetReseller",
  {
    reseller: FlowDataField;
  }
>;

export type BindsFlowData = BaseFlowData<
  "Binds",
  { adjustmentDate: string; overrideBinds: boolean; supressFees: boolean }
>;

export type ConfirmationFlowData = BaseFlowData<
  "Confirmation",
  { message: string }
>;

export type MissingConfigFlowData = BaseFlowData<
  "MissingConfig",
  { message: string }
>;

export type LogisticsFlowData = BaseFlowData<
  "Logistics",
  {
    selectedArticle: ValidValue;
    addressText: string;
  }
>;

export type CreditCheckFlowData = BaseFlowData<
  "CreditCheck",
  {
    identifier: string;
    creditRating: CreditRating;
    allowOverrideWarning: boolean;
    allowOverrideNotOk: boolean;
    overrideSelected: boolean;
  }
>;

//todo add types here
export type CreditInvoiceFlowData = BaseFlowData<
  "CreditInvoice",
  {
    approve: boolean;
    approvalLimitInclTax: number;
    creditDate: string;
    creditReason: Omit<ValidValue, "icon" & "entityTypeId">;
    creditReasons: Omit<ValidValue, "icon" & "entityTypeId">[];
    invoiceRoundingEnabled: boolean;
    invoiceRoundingPrecision: number;
    externalInvoiceId: string;
    invoiceId: number;
    currencyCode: string;
    creditedAmountInclTax: number;
    invoiceTotalAmountInclTax: number;
    maxAmountToCreditInclTax: number;
    rows: CreditInvoiceRow[];
    /** Controls if amounts are displayed/input including or excluding tax */
    amountsInclTax: boolean;
  }
>;

export interface CreditInvoiceRow {
  invoicedAmount: number;
  invoicedAmountExclTax: number;
  invoicedAmountInclTax: number;
  creditedNetAmount: number;
  creditedTotalAmount: number;
  creditedTotalAmountExclTax: number;
  creditedTotalAmountInclTax: number;
  credited: boolean;
  currencyCode: string;
  fee: string;
  originalInvoiceDetailId: number;
  periodEnd: string;
  periodStart: string;
  taxRate: number;
}

export type PayInvoiceFlowData = BaseFlowData<
  "PayInvoice",
  {
    remainingAmount: number;
    paidAmount: number;
    applicablePaymentTypes: ValidValue[];
    paymentReference: string;
    paymentDate: string;
    currencyCode: string;
    paymentType: ValidValue;
    externalInvoiceId: string;
  }
>;

export type CreatePaymentFlowData = BaseFlowData<
  "CreatePayment",
  {
    paidAmount: number;
    applicablePaymentTypes: ValidValue[];
    paymentReference: string;
    paymentDate: string;
    currencyCode: string;
    paymentType: ValidValue;
  }
>;

export type EditCustomerNoteFlowData = BaseFlowData<
  "EditCustomerNote",
  {
    noteText: string;
    status: ValidValue;
    allStatuses: ValidValue[];
  }
>;

export type ChangeDueDateFlowData = BaseFlowData<
  "ChangeDueDate",
  {
    externalInvoiceId: string;
    dueDate: string;
  }
>;

export type ConfigureInvoiceDunningFlowData = BaseFlowData<
  "ConfigureInvoiceDunning",
  {
    externalInvoiceId: string;
  }
>;
export type ActionListFlowData = BaseFlowData<
  "ActionList",
  { action: FlowStepAction | undefined }
>;

export type TableFlowData = BaseFlowData<
  "Table",
  { selectedIds: number[]; selectedNames: string[] }
>;

export type UpgradeDowngradeFlowData = BaseFlowData<
  "UpgradeDowngrade",
  {
    productOfferingOptions: ProductOfferingOption[];
    intSelectedIndex: number;
  }
>;

export type SelectProductFlowData = BaseFlowData<
  "SelectProduct",
  {
    productOfferingOptions: ProductOfferingOption[];
    intSelectedIndex: number;
  }
>;

export type SelectStepFlowData = BaseFlowData<
  "SelectStep",
  { selectedStepId: string }
>;

export type AdjustmentPropertiesFlowData = BaseFlowData<
  "AdjustmentProperties",
  {
    priceTypes: ValidValue[]; // Values for the combo box to select a price type
    priceTypeId: number; // id of the selected price type
    priceTypeDescriptionsMap: Record<number, string[]>; // predefined descriptions for each price type
    adjustmentDescription: string; // Description selected/input by the user
    immediateInvoicingEnabled: boolean; // Value for/of the 'enable immediate invoicing' check box
    futureInvoiceDates: string[]; // Values for the combo box to select invoice date
    earliestInvoiceDate: string; // Date selected by the user from the drop-down list that is visible when immediateInvoicingEnabled = false
    immediateInvoiceDate: string; // Date input by the user using the Date Picker that is visible when immediateInvoicingEnabled = true
  }
>;

export type AmountWithVATFlowData = BaseFlowData<
  "AmountWithVAT",
  {
    inputAmountInclVat: boolean; // Value for/of the 'Input amount including VAT' check box
    amount: number; // Amount including VAT if inputAmountInclVat === true or excluding VAT if inputAmountInclVat === false
    vatRate: number; // Applicable VAT rate
    currencyCode: string; // Currency code that corresponds to the customer
  }
>;

export type AdjustmentAmountFlowData = BaseFlowData<
  "AdjustmentAmount",
  {
    generalLedgerInfo: ValidValue; // General Ledger Information corresponding to the selected Price Type
    taxBundleInfo: ValidValue; // Tax Bundle Information corresponding to the selected Price Type
  } & Omit<AmountWithVATFlowData, "uiComponent">
>;

/**
 * Data structure used by the Decision component.
 *
 * @remarks The following properties are used by the Decision component:
 * - decisionChoices: An array of ValidValues that will be displayed
 *   in the combo box in which the user selects a decision.
 * - selectedDecision: Name of the Decision selected by the user.
 *
 */
export type DecisionFlowData = BaseFlowData<
  "Decision",
  {
    /**
     * An array of ValidValues that will be displayed in the combo box in which the user selects a decision.
     */
    decisionChoices: ValidValue[];
    /**
     * Name of the Decision selected by the user.
     */
    selectedDecision: string;
  }
>;

export type TransferProductFlowData = BaseFlowData<
  "TransferProduct",
  {
    copyProduct: boolean;
    copyProductChangeAllowed: boolean;
    transferBinds: boolean;
    deactivateStatusReasonId: number;
    activateStatusReasonId: number;

    applicableActivateReasons: ValidValue[];
    applicableDeactivateReasons: ValidValue[];
  }
>;

export type ProductPartsConfigurationFlowData = BaseFlowData<
  "ProductPartsConfiguration",
  {
    cardinalityRowList: CardinalityRow[];
  }
>;

export type EditInventoryFlowData = BaseFlowData<
  "EditInventory",
  {
    statusList: ValidValue[];
    locationList: ValidValue[];
    statusId: number;
    locationId: number;
  }
>;

export type WebSetupSaveAsFlowData = BaseFlowData<
  "WebSetupSaveAs",
  {
    entityType: EntityType;
    newName: string;
    newPath: string;
    showCopyChildren: boolean;
    copyChildren: boolean;
    copyTables: boolean;
    copyTableFieldDefinitions: boolean;
    copyFlows: boolean;
    copyViews: boolean;
    copyWidgets: boolean;
    copyScreens: boolean;
    copyActions: boolean;
    copyFlowSteps: boolean;
    copySearchFilters: boolean;
    showNameField: boolean;
    showPathField: boolean;
  }
>;

export type FlowData =
  | AddressFieldsFlowData
  | BindsFlowData
  | FieldsFlowData
  | OrderAttributesFlowData
  | RepaymentFlowData
  | ConfigureInvoiceDunningFlowData
  | ChangeCustomerStatusFlowData
  | ChangeEntityStatusFlowData
  | ChangeProductStatusFlowData
  | ConfirmationFlowData
  | EditInventoryFlowData
  | FindInventoryFlowData
  | FindNetworkElementFlowData
  | ActionListFlowData
  | LogisticsFlowData
  | MissingConfigFlowData
  | CreditInvoiceFlowData
  | CreditCheckFlowData
  | PayInvoiceFlowData
  | UpgradeDowngradeFlowData
  | SelectProductFlowData
  | CreatePaymentFlowData
  | EditCustomerNoteFlowData
  | ProductPartsConfigurationFlowData
  | ChangeDueDateFlowData
  | TableFlowData
  | SelectStepFlowData
  | SetResellerFlowData
  | AmountWithVATFlowData
  | AdjustmentAmountFlowData
  | AdjustmentPropertiesFlowData
  | DecisionFlowData
  | TransferProductFlowData
  | WebSetupSaveAsFlowData;
