import {
  FormDatePickerValue,
  FormDateTimePickerProps,
} from "common/components";
import {
  addDays,
  maxDate as getMaxDate,
  minDate as getMinDate,
} from "common/utils";
import { CalendarInfo, CalendarRange, useFetchCalendarRanges } from "core/api";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

/**
 * Provides values and functions for datetime pickers calendar availability
 * @param fieldName Field name
 * @param calendar Calendar info
 * @returns
 */
export function useCalendarRangeAvailability(
  fieldName: string,
  calendar: CalendarInfo | undefined
) {
  const { calendarSetId, minDaysAhead, maxDaysAhead } = calendar ?? {};
  const fromDate = useMemo(() => addDays(minDaysAhead ?? 0), [minDaysAhead]);
  const toDate = useMemo(() => addDays(maxDaysAhead ?? 0), [maxDaysAhead]);
  const { data: calendarRanges } = useFetchCalendarRanges({
    calendarSetId,
    from: fromDate,
    to: toDate,
  });
  const minDate = useMemo(
    () =>
      calendarRanges && getMinDate(...calendarRanges.map(({ from }) => from)),
    [calendarRanges]
  );
  const maxDate = useMemo(
    () => calendarRanges && getMaxDate(...calendarRanges.map(({ to }) => to)),
    [calendarRanges]
  );
  const { getValues } = useFormContext();

  const shouldDisableDate: FormDateTimePickerProps["shouldDisableDate"] = (
    day
  ) => {
    if (!calendarSetId) {
      return false;
    }
    if (!calendarRanges) {
      return true;
    }
    return !getCalendarRange(calendarRanges, dayjs(day));
  };

  const shouldDisableTime: FormDateTimePickerProps["shouldDisableTime"] = (
    timeValue,
    clockType
  ) => {
    if (!calendarSetId) {
      return false;
    }
    if (!calendarRanges) {
      return true;
    }
    const value: string | Date | FormDatePickerValue = getValues(fieldName);
    const isPickerValue =
      typeof value !== "string" && "keyboardInputValue" in value;
    const currentValue = dayjs(isPickerValue ? value.event.$d : value);
    const { startHours, startMinutes, endHours, endMinutes } = getTimeRange(
      calendarRanges,
      currentValue
    );
    switch (clockType) {
      case "hours":
        const isWithinHours =
          timeValue.hour() >= startHours && timeValue.hour() <= endHours;
        return !isWithinHours;
      case "minutes":
        const currentHour = currentValue.get("hours");
        const isFirstHour = currentHour === startHours;
        const isLastHour = currentHour === endHours;
        if (isFirstHour) {
          return timeValue.minute() < startMinutes;
        }
        if (isLastHour) {
          return timeValue.minute() > endMinutes;
        }
        return false;
      case "seconds":
      default:
        return false;
    }
  };

  return {
    minDate,
    maxDate,
    shouldDisableDate,
    shouldDisableTime,
  };
}

/**
 * Gets range matching target date
 * @param ranges Calendar ranges
 * @param date Target date
 * @returns Range matching target date if available
 */
function getCalendarRange(ranges: CalendarRange[], date: dayjs.Dayjs) {
  return ranges.find(({ from }) => dayjs(date).isSame(from, "day"));
}

interface TimeRange {
  /** First available hour */
  startHours: number;
  /** First available minute */
  startMinutes: number;
  /** Last available hour */
  endHours: number;
  /** Last available minute */
  endMinutes: number;
}

/**
 * Get time range for target date
 * @param ranges Calendar ranges
 * @param date Target date
 * @returns Time range
 */
function getTimeRange(ranges: CalendarRange[], date: dayjs.Dayjs): TimeRange {
  const targetRange = getCalendarRange(ranges, date);
  const startDate = dayjs(targetRange?.from);
  const startHours = startDate.get("hours");
  const startMinutes = startDate.get("minutes");
  const endDate = dayjs(targetRange?.to).subtract(1, "minute");
  const endHours = endDate.get("hours");
  const endMinutes = endDate.get("minutes");
  return {
    startHours,
    startMinutes,
    endHours,
    endMinutes,
  };
}
