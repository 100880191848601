import { EntityRef, EntityType, FlowData, FlowStep } from "core/api";

export interface FlowDataMap {
  /** Flow step data */
  [stepId: string]: FlowData;
}

export enum FlowVariant {
  STEPPER = "STEPPER",
  DETAILED_STEPPER = "DETAILED_STEPPER",
  SINGLE_STEP = "SINGLE_STEP",
  FORM = "FORM",
  STEPPER_FORM = "STEPPER_FORM",
}

export enum FlowDialogSize {
  AUTO = "AUTO",
  FULLSCREEN = "FULLSCREEN",
  NORMAL = "NORMAL",
  LARGE = "LARGE",
}

export interface FlowConfig {
  variant: FlowVariant;
  dialogSize: FlowDialogSize;
  showStepTitle: boolean;
  /** (optional) A label for the 'Apply' button that is displayed on the
   * in the SingleSteppedDialog */
  buttonLabelApply?: string;
  /** (optional) A label for the 'Cancel' button that is displayed on the
   * in the SingleSteppedDialog */
  buttonLabelCancel?: string;
}

/**
 * An entry in the flow context
 */
export interface ContextItem {
  name: string;
  entityRef: EntityRef;
  itemId: number;
  newItem: boolean;
  addedBy: string;
  belongsTo: string;
}

/**
 * Server side flow context, just kept in the flow. Not for the UI to act on.
 */
export interface FlowContext {
  /**
   * The entity the flow was invoked for, can be NONE.
   */
  parent: EntityRef;

  /**
   * The current list of items in the context.
   */
  entries: ContextItem[];
}

/**
 * Container for additional input to a flow.
 *
 * @remarks
 * Additional input is usually additional entity refs, the UIs screenEntities.
 */
export interface FlowInput {
  /**
   * The current screen entities, none or one to many.
   */
  screenEntities?: EntityRef[] | undefined;

  /**
   * Selected entities to perform the flow on, this in case of multi-selection in
   * a table.
   */
  selectedEntities?: EntityRef[] | undefined;
}

export interface Flow {
  /** Flow id */
  flowId: number;
  /** Action id */
  actionId: number;
  /** Unique GUID used for "flow-session" locking (inventories) */
  lockUID: string;

  /** Unique GUID used for flow, initial for the initial flow started */
  flowUID: string;
  /** Flow config (UI) */
  config: FlowConfig;
  /** Flow name */
  name: string;
  /** Flow description */
  description: string;
  /** Optional title to show if set, else use name */
  title?: string;
  /** When current data was evaluated (Epoch time) */
  evaluatedAt?: number;
  /** Current step id */
  currentStepId: string;
  /** Flow steps */
  steps: FlowStep[];
  /** Flow steps configured in flow, but hidden by */
  hiddenSteps?: FlowStep[];
  /** Flow steps data */
  data: FlowDataMap;

  /** Flow steps selected map */
  selectedEntities?: Map<EntityType, number>;

  /** Id of related Screen */
  screenId: number;
  /** Id of related View */
  viewId: number;
  /** Id of related Widget */
  widgetId: number;
  /** If the action flow is invoked from a table */
  invokedFromTable: boolean;
  /** The screen entities when flow was opened */
  screenEntities?: EntityRef[] | undefined;

  context: FlowContext;
}
