import { PrinterQueueSettings } from "../models";
import { TableSettingsDefault } from "./TableSettingsDefault";

export const PrinterQueueAppDefaults: PrinterQueueSettings = {
  //Spread syntax used to avoid uncommon scenario where due to some state
  //management, updating one item updates the other as well --ERIJUN
  documentTypeTableSettings: { ...TableSettingsDefault },
  printerQueueDefinitionTableSettings: { ...TableSettingsDefault },
  printerQueueTableSettings: {},
};
