import { Box, Typography } from "common/components";
import { ActionParams } from "core/actions/flows";
import { ActionConfig, EntityType } from "core/api";
import { useConfigMode } from "core/auth";
import { WebSetupActionsMenu } from "core/components";
import { useTranslation } from "i18n";
import { ReactElement } from "react";

export interface WebSetupToolbarProps {
  /** Type of entity, determines styling */
  entityType: EntityType;
  /** Id of entity */
  entityId: number | undefined;
  /** Children passed through component. Only applicable for entity type WEB_WIDGET */
  children?: any;
  /** Is the entity inside of a menu? Only applicable for entity type WEB_ACTION */
  inMenu?: boolean;
  /** Ability to trigger actions fetched from db */
  triggerAction?: (
    config: ActionConfig<any>,
    params?: ActionParams | undefined
  ) => void;
  /** Enables visibility of actions fetched from db */
  enableActions?: boolean;
}

/**
 * Generates a container box with correct styling and placement for config
 * based editing of entity. Visible only when configMode is enabled.
 *
 * Any future configMode edits performed on entities
 * should be made in this component.
 *
 * @returns ReactElement | null
 */
export function WebSetupToolbar({
  entityType,
  entityId,
  children,
  inMenu,

  triggerAction = () => {},
}: WebSetupToolbarProps): ReactElement | null {
  const { state: configModeEnabled } = useConfigMode();

  /** Base config for container boxes and chips */
  const baseConfig = {
    box: {
      props: {
        alignItems: "center",
        height: "fit-content",
        width: "100%",
        bgcolor: "hsl(0, 0%, 0%, 0)",
        borderRadius: "8px",
        marginBottom: "1px",

        display: "grid",
        gridTemplateColumns: "1fr auto 1fr",
        justifyItems: "center",
      },
    },
  };

  const { t } = useTranslation(["common"]);

  /** Entity type specific configuration */
  const config: Record<string, any> = {
    [EntityType.WEB_SCREEN]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 86%, 95%)" },
      },
      label: t("common:entityTypeName.webScreen_one"),
      setUpPath: "/8/web-setup/screens/" + entityId,
    },
    [EntityType.WEB_VIEW]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 70%, 70%)" },
      },
      label: t("common:entityTypeName.webView_one"),
      setUpPath: "/8/web-setup/views/" + entityId,
    },
    [EntityType.WEB_TABLE]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 86%, 95%)" },
      },
      label: t("common:entityTypeName.webTable_one"),
      setUpPath: "/8/web-setup/tables/" + entityId,
    },

    [EntityType.WEB_TABLE_OPTION]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 86%, 95%)" },
      },
      label: t("common:entityTypeName.webTableOption_one"),
      setUpPath: "/8/web-setup/table-options/" + entityId,
    },

    [EntityType.WEB_TABLE_FIELD_DEFINITION]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 86%, 95%" },
      },
      label: "",
      setUpPath: "/8/web-setup/table-field-definitions/" + entityId,
    },

    [EntityType.FLOW]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 70%, 70%)" },
      },
      label: t("common:entityTypeName.webFlow_one"),
      setUpPath: "/8/web-setup/flows/" + entityId,
    },
    [EntityType.FLOW_STEP_DEFINITION]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 70%, 80%)" },
      },
      label: t("common:entityTypeName.webStep_one"),
      setUpPath: "/8/web-setup/flow-step-definitions/" + entityId,
    },

    [EntityType.WEB_APPLICATION]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 70%, 70%)" },
      },
      label: t("common:entityTypeName.webApplication_one"),
      setUpPath: "/8/web-setup/applications/" + entityId,
    },

    [EntityType.WEB_APP_CONFIG]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 70%, 80%)" },
      },
      label: t("common:entityTypeName.webAppConfig_one"),
      setUpPath: "/8/web-setup/app-configs/" + entityId,
    },

    [EntityType.WEB_WIDGET]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 70%, 80%)" },
      },
      label: t("common:entityTypeName.webWidget_one"),
      setUpPath: "/8/web-setup/widgets/" + entityId,
    },
    [EntityType.WEB_NAVIGATION_GROUP]: {
      box: {
        props: { ...baseConfig.box.props, bgcolor: "hsl(198, 70%, 80%)" },
      },
      label: t("common:entityTypeName.webNavigationGroup_one"),
      setUpPath: "/8/web-setup/navigation-groups/" + entityId,
    },
    [EntityType.WEB_ACTION]: {
      setUpPath: "/8/web-setup/actions/" + entityId,
    },
  };

  const configBar = (config: any, minimal = false) => {
    const toolbarLabel = minimal ? null : (
      <Typography fontSize={"small"} sx={{ gridColumnStart: 2 }}>
        {config.label + " " + entityId}
      </Typography>
    );
    return (
      <Box
        {...config.box.props}
        data-cy={
          "webSetupToolbar" + config.label.replace(/[\s\\/]/g, "") + entityId
        }
      >
        {toolbarLabel}
        <Box sx={{ justifySelf: "right" }}>
          <WebSetupActionsMenu
            entityType={entityType}
            entityId={entityId}
            triggerAction={triggerAction}
            setUpPath={config.setUpPath}
          />
        </Box>
      </Box>
    );
  };

  if (configModeEnabled) {
    switch (entityType) {
      case EntityType.WEB_WIDGET:
        return (
          <Box>
            {configBar(config[entityType])}
            {children}
          </Box>
        );
      case EntityType.WEB_ACTION:
        return (
          <WebSetupActionsMenu
            entityId={entityId}
            entityType={entityType}
            inMenu={inMenu}
            triggerAction={triggerAction}
            setUpPath={config[entityType].setUpPath}
          />
        );
      case EntityType.WEB_TABLE_FIELD_DEFINITION:
        return configBar(config[entityType], true);
      default:
        return configBar(config[entityType]);
    }
  }
  if (!configModeEnabled) {
    if (entityType == EntityType.WEB_WIDGET) {
      return children;
    }
  }
  return null;
}
