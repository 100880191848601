import { Box, FormCheckBox, FormTextField } from "common/components";
import { addFlowStepPrefix } from "common/utils";
import {
  EntityType,
  WebSetupSaveAsFlowData,
  WebSetupSaveAsFlowStep,
} from "core/api";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { useEntityTitle } from "../../../../hooks";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type WebSetupSaveAsProps = FlowStepComponentProps<
  WebSetupSaveAsFlowStep,
  WebSetupSaveAsFlowData
>;

/** Save as component for web setup */
export function WebSetupSaveAs({
  control,
  data,
  flowStep,
}: WebSetupSaveAsProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const {
    showCopyChildren,
    newName,
    copyChildren,
    copyActions,
    copyFlows,
    copyFlowSteps,
    copyTableFieldDefinitions,
    copySearchFilters,
    copyTables,
    copyScreens,
    copyViews,
    copyWidgets,
    showNameField,
    showPathField,
    newPath,
  } = data;

  const context = useFormContext();

  const copyChildrenSelected = context?.watch(
    addFlowStepPrefix(flowStep, "copyChildren"),
    data.copyChildren
  );

  const copyTablesTicked = context?.watch(
    addFlowStepPrefix(flowStep, "copyTables"),
    data.copyTables
  );

  const copyActionsTicked = context?.watch(
    addFlowStepPrefix(flowStep, "copyActions"),
    data.copyFlows
  );

  const copyFlowsTicked = context?.watch(
    addFlowStepPrefix(flowStep, "copyFlows"),
    data.copyFlows
  );

  const { getEntityTitle } = useEntityTitle();

  return (
    <StyledStepContentWrapper>
      {showNameField && (
        <FormTextField
          control={control}
          label={t("common:webSetup.newName")}
          fieldName={addFlowStepPrefix(flowStep, "newName")}
          defaultValue={newName}
          required
          data-cy="nameField"
        />
      )}

      {showPathField && (
        <FormTextField
          control={control}
          label={t("common:webSetup.newPath")}
          fieldName={addFlowStepPrefix(flowStep, "newPath")}
          defaultValue={newPath}
          required
          data-cy="pathField"
        />
      )}

      {showCopyChildren && (
        <>
          <FormCheckBox
            control={control}
            fieldName={addFlowStepPrefix(flowStep, "copyChildren")}
            label={t("common:webSetup.copyChildren")}
            defaultValue={copyChildren}
            data-cy="copyChildrenField"
          />
          <Box
            marginLeft={"2em"}
            sx={{ display: "grid", gridTemplateColumns: "auto auto auto" }}
          >
            <FormCheckBox
              control={control}
              fieldName={addFlowStepPrefix(flowStep, "copyActions")}
              label={getEntityTitle(EntityType.WEB_ACTION, 2)}
              defaultValue={copyActions}
              disabled={!copyChildrenSelected}
              data-cy="copyActionsField"
            />

            <FormCheckBox
              control={control}
              fieldName={addFlowStepPrefix(flowStep, "copyTables")}
              label={getEntityTitle(EntityType.WEB_TABLE, 2)}
              defaultValue={copyTables}
              disabled={!copyChildrenSelected}
              data-cy="copyTablesField"
            />

            <FormCheckBox
              control={control}
              fieldName={addFlowStepPrefix(flowStep, "copyScreens")}
              label={getEntityTitle(EntityType.WEB_SCREEN, 2)}
              defaultValue={copyScreens}
              disabled={!copyChildrenSelected}
              data-cy="copyScreensField"
            />

            <FormCheckBox
              control={control}
              fieldName={addFlowStepPrefix(flowStep, "copyFlows")}
              label={getEntityTitle(EntityType.FLOW, 2)}
              defaultValue={copyFlows}
              disabled={!copyChildrenSelected || !copyActionsTicked}
              data-cy="copyFlowsField"
            />

            <FormCheckBox
              control={control}
              fieldName={addFlowStepPrefix(
                flowStep,
                "copyTableFieldDefinitions"
              )}
              label={getEntityTitle(EntityType.WEB_TABLE_FIELD_DEFINITION, 2)}
              defaultValue={copyTableFieldDefinitions}
              disabled={!copyChildrenSelected || !copyTablesTicked}
              data-cy="copyTableFieldDefinitionsField"
            />

            <FormCheckBox
              control={control}
              fieldName={addFlowStepPrefix(flowStep, "copyViews")}
              label={getEntityTitle(EntityType.WEB_VIEW, 2)}
              defaultValue={copyViews}
              disabled={!copyChildrenSelected}
              data-cy="copyViewsField"
            />

            <FormCheckBox
              control={control}
              fieldName={addFlowStepPrefix(flowStep, "copyFlowSteps")}
              label={getEntityTitle(EntityType.FLOW_STEP_DEFINITION, 2)}
              defaultValue={copyFlowSteps}
              disabled={
                !copyChildrenSelected || !copyFlowsTicked || !copyActionsTicked
              }
              data-cy="copyFlowSteps"
            />
            <FormCheckBox
              control={control}
              fieldName={addFlowStepPrefix(flowStep, "copySearchFilters")}
              label={getEntityTitle(EntityType.WEB_SEARCH_FILTER, 2)}
              defaultValue={copySearchFilters}
              disabled={!copyChildrenSelected || !copyTablesTicked}
              data-cy="copySearchFiltersField"
            />

            <FormCheckBox
              control={control}
              fieldName={addFlowStepPrefix(flowStep, "copyWidgets")}
              label={getEntityTitle(EntityType.WEB_WIDGET, 2)}
              defaultValue={copyWidgets}
              disabled={!copyChildrenSelected}
              data-cy="copyWidgetsField"
            />
          </Box>
        </>
      )}
    </StyledStepContentWrapper>
  );
}
