import { fieldKeyToString, getEntityIdKey, getEntityTitle } from "common/utils";
import {
  EntityData,
  EntityRef,
  EntityType,
  FieldConfig,
  FieldKey,
  Screen,
  ScreenConfig
} from "core/api";
import { ScreenEntities } from "core/store";

const paramRegex = /:([a-zA-Z.]*)/g;

/**
 * Formats and returns the first URL to completely
 * match entity values in URL params
 * @param entity Entity data
 * @param urls URLs sorted by priority
 * @returns The first matching URL
 */
export function getEntityUrl(
  entity: Partial<EntityData>,
  url: string | undefined,
  paramReplacements?: Record<string, string>
): string {
  if (!url) {
    return "";
  }
  const hasParam = (param: string) =>
    ![undefined, null, -1].includes(getEntityValue(entity, param));

  const params = getUrlPathParams(url, paramReplacements);
  const hasAllParams = params.reduce(
    (matchesAll, param) => matchesAll && hasParam(param),
    true
  );
  const formattedUrl = formatEntityUrl(entity, url, params);
  if (hasAllParams) {
    return formattedUrl;
  } else {
    return formattedUrl + "/this/url/is/not/correct";
    /* Below seems more correct but then selfcare fails //TODO JSO 
    throw new Error(
      "Configuration error, unable to create a complete url, placeholders remain, partial URL is " +
        formattedUrl
    );
    */
  }
}

/**
 * Returns list of all URL params
 * @param url Target url
 * @returns URL params
 */
export function getUrlPathParams(
  url: string | undefined,
  paramReplacements?: Record<string, string>
): string[] {
  if (!url) {
    return [];
  }
  return Array.from(url.matchAll(paramRegex)).map(
    ([_, param]) => (paramReplacements && paramReplacements[param]) ?? param
  );
}

/**
 * Get entries with entity values mapped to parameters from path
 * @param entity Entity data
 * @param path Url path
 * @returns Mapped entity values
 */
export function getUrlPathParamsValues(
  entity: Partial<EntityData> | undefined,
  path: string
): Record<string, any> {
  const keys = getUrlPathParams(path);
  return keys.reduce(
    (pathParams, key) => ({
      ...pathParams,
      [key]: entity?.[key],
    }),
    {}
  );
}

/**
 * Creates an url to an entity.
 * @param entity Entity resource
 * @param path Path with parameters matching entity keys e.g. '/path/:param'
 * @param keys Entity keys to format in path. Defaults to use all params
 * @returns The formatted entity url
 */
export function formatEntityUrl(
  entity: Partial<EntityData> | undefined,
  path: string,
  keys?: string[]
): string {
  const _keys = keys ? keys : getUrlPathParams(path);
  return _keys?.reduce((formattedUrl, key) => {
    const entityValue = getEntityValue(entity, key);
    if (!key || [null, undefined, ""].includes(entityValue)) {
      return formattedUrl;
    }
    return formattedUrl.replace(`:${key}`, `${entityValue}`);
  }, path ?? "");
}

/**
 * Get entity value from key.
 * @param entity Entity data
 * @param key Entity key. Supports nested values e.g. 'key.nextKey'
 * @returns Entity value
 */
export function getEntityValue(
  entity: Partial<EntityData> | undefined,
  key: keyof EntityData
): EntityData[string] {
  return key.split(".").reduce((_entity, subKey) => _entity?.[subKey], entity);
}

/**
 * Map fields by string formatted FieldKey
 * @param fields Fields to include
 * @returns Field map or empty map
 */
export function mapFields<TField extends { key: FieldKey }>(
  fields: TField[] | undefined
): Record<string, TField> {
  return (
    fields?.reduce(
      (map: Record<string, TField>, field: TField) => ({
        ...map,
        [fieldKeyToString(field.key)]: field,
      }),
      {}
    ) ?? {}
  );
}

/**
 * Checks if string includes params
 * @param path Target path
 * @returns If path includes params
 */
export function stringHasParams(path: string): boolean {
  return path.includes(":");
}

/**
 * Use in filter to exclude screens with path params
 * @param screen Screen to check path to
 * @returns If the screen's path doesn't include a param
 */
export function filterPathsWithParams(screen: Screen): boolean {
  return !stringHasParams(screen.routeProps.path);
}

/**
 * Check if any screen loosely matches current route.
 *
 * NOTE: Used to decide wether the navigation group header
 * should match loosely or exact to give an indication
 * to the user where in the application they are at the
 * moment.
 *
 * @param path Pathname of route
 * @param screens Screens to compare paths with
 * @returns If any path matches
 */
export function matchesAnyScreen(
  path: string,
  screens?: ScreenConfig[]
): boolean {
  if (!screens) {
    return false;
  }
  return (
    screens.filter(
      ({ routeProps, showInSidebar }) =>
        showInSidebar && path.startsWith(routeProps.path)
    ).length > 0
  );
}

/**
 * Filter screens to remove those with params in paths
 * @param screens Screens to filter
 * @returns Filtered screens
 */
export function filterNavigationScreens(
  screens: ScreenConfig[]
): ScreenConfig[] {
  return screens?.filter(
    ({ routeProps, showInSidebar }) =>
      showInSidebar && !stringHasParams(routeProps.path)
  );
}

export function formatDynamicNavigationGroups(
  screen: ScreenConfig,
  dynamicNavigationItems: EntityData[],
  fieldConfigMap: Record<string, FieldConfig>
): ScreenConfig[] {
  //TODO; Have another pass at handling the subscreens, maybe needs to be done
  //      a bit more thoroughly?
  return dynamicNavigationItems.map((item) => {
    return {
      ...screen,
      title: getEntityTitle(
        item,
        screen.title,
        fieldConfigMap,
        screen.entityFetchUrl
      ),
      routeProps: {
        path: formatEntityUrl(item, screen.routeProps.path),
        relativePath: formatEntityUrl(item, screen.routeProps.relativePath),
      },
      screens: screen.screens.map((subscreen) => {
        return {
          ...subscreen,
          title: getEntityTitle(
            item,
            screen.title,
            fieldConfigMap,
            screen.entityFetchUrl
          ),
          routeProps: {
            path: formatEntityUrl(item, subscreen.routeProps.path),
            relativePath: formatEntityUrl(
              item,
              subscreen.routeProps.relativePath
            ),
          },
        };
      }),
    };
  });
}

export function addToScreenEntities(
  entities: ScreenEntities,
  entityType: EntityType,
  entityData: Partial<EntityData> | undefined
) {
  return {
    ...entities,
    [entityType]:
      entityType !== EntityType.NONE
        ? {
            entityType,
            entityData,
          }
        : undefined,
  };
}

/**
 * Convert the template string to a string without placeholders. 
 * 
 * @remarks
 * The template format is like the one for React Router, example /customers/:cutomerNo/products
 * Picks the available placeholders and placeholder values from provified screenentities.
 * 
 * @param template
 * @param screenEntities 
 
 * @returns string with placeholders replaced.
 */
export function replacePlaceholders(
  template: string,
  screenEntities: ScreenEntities
): string {
  return Object.entries(screenEntities)
    .filter(([, value]) => value !== undefined)
    .reduce((formattedUrl, [, elem]) => {
      return formattedUrl.replace(
        `:${getEntityIdKey(elem.entityType)}`,
        elem.entityData.entityId
      );
    }, template);
}

/**
 * Helper function to transform the ScreenEntities to an array of EntityRefs
 *
 * @remarks
 * The EntityRefs created in the UI is actually just partially filled with data, the server has more members in it's EntityRef
 *
 * @param screenEntities
 *
 * @returns An array of entity refs.
 */
export function toEntityRefs(
  screenEntities: ScreenEntities
): EntityRef[] | undefined {
  return Object.entries(screenEntities)
    .filter(([, value]) => value !== undefined && value.entityData != undefined)
    .map(
      ([, value]) =>
        ({
          entityType: value.entityType,
          entityTypeId: value.entityData.entityTypeId,
          entityId: value.entityData.entityId,
        } as EntityRef)
    );
}
