export const API = {
  /**
   * The authentication service authenticate and authorize
   * towards the users defined in Billiant
   */
  alertService: {
    entityAlerts: "/alerts/:entityType/:entityId",
  },
  auth: {
    /** GET: to get a list of identity providers */
    identityproviders: "/auth/identityproviders",
    /** POST: Login to get access, creates a bearer token */
    login: "/auth/login",
    /** POST: Login to get access, creates a bearer token */
    login_provider: "/auth/login/:provider",
    /** POST: Request a one time password with an approved username, DOES NOT give access to user */
    login_otp: "/auth/login/:userName",
    /** GET: To refresh to get a new Bearer token */
    refresh: "/auth/refresh",
    /** POST: Log out from BSSWeb Server */
    logout: "/auth/logout",
    /** GET: To get server info, like version and license */
    serverInfo: "/auth/server-info",
    /** POST: Generate kindly token */
    kindlyToken: "/auth/kindly/:chatId",
  },
  /**
   * The common service provide access to general purpose
   * configuration data. Data delivered is filtered on the
   * current users default customer group
   */
  common: {
    /** GET: Find reports */
    reports: "/common/reports",
    calendars: {
      ranges: "/common/calendars/:calendarSetId/ranges",
    },
    /** GET: Find customer groups */
    customerGroups: "/common/customergroups",
    /** GET: Find customer status reasons */
    customerStatusReasons: "/common/customer-status-reasons",
    /** GET: Find subscription status reasons */
    subscriptionStatusReasons: "/common/subscription-status-reasons",
    /** GET: Find inventory statuses */
    inventoryStatuses: "/common/inventory-statuses",
    /** GET: Find invoice groups */
    invoiceGroups: "/common/invoicegroups",
    /** GET: Find enums */
    enums: "/common/codes",
    /** GET: Find document statuses */
    documentStatus: "/common/codes/DocumentStatus",
    /** GET: Find currencies */
    currencies: "/common/currencies",
    /** GET: Find system users */
    systemUsers: "/common/system-users",
  },
  /**
   * The customer service provide access to customer data,
   * Data delivered is filtered on the current users default
   * customer group
   */
  customerservice: {
    /** GET: fetch customer with specified customer no */
    customer: "/customers/:customerNo",
    customers: "/customers",
    customerCategories: "/customers/:customerNo/categories",
    customerInvoices: "/customers/:customerNo/invoices",
    customerInvoicePreview: "/customers/:customerNo/invoice-preview",
    customerInventories: "/customers/:customerNo/inventory",
    customerProducts: "/customers/:customerNo/products",
    customerNpCases: "/customers/:customerNo/np-cases",
    customerNotes: "/customers/:customerNo/notes",
    customerTasks: "/customers/:customerNo/tasks",
    customerDebt: "/customers/:customerNo/aged-debt",
    customerPayments: "/customers/:customerNo/payments",
    customerUsers: "/customers/:customerNo/users",
    creditRating: {
      latest: "/customers/:customerNo/creditRating/current",
      search: "/customers/:customerNo/creditRating",
    },
    customerSearchHistory:
      "/customers/:customerNo/history/entityType/:entityType",
    customerAncestor: "/customers/:customerNo/ancestor",
    customerDescendants: "/customers/:customerNo/descendants",
    customerAdjustments: "/customers/:customerNo/adjustments",
    customerAdjustment: "/customers/adjustments/:adjustmentId",
    customerBuckets: "/customers/:customerNo/buckets",
    customerOrders: "/customers/:customerNo/orders",
    nextInvoiceDate: "/customers/:customerNo/next-invoice-date",
    productOfferingOptions: "/customers/:customerNo/offering-options",
  },
  documents: {
    documents: "/documents",
    document: "/documents/:documentId",
    documentFile: "/documents/:documentId/file",
    documentTypes: "/documents/documentTypes",
    documentType: "/documents/documentTypes/:documentTypeId",
    documentGroups: "/documents/documentGroups",
    documentGroup: "/documents/documentGroups/:documentGroupId",
    documentStores: "/documents/documentStores",
    documentStore: "/documents/documentStores/:documentStoreId",
    combined: "/documents/combine",
  },

  messages: {
    messages: "/messages",
    message: "/messages/:messageId",
  },

  /**
   * The order service provide access to order data
   */
  orderservice: {
    order: "/orders/:orderId",
    orders: "/orders",
    orderExceptions: "/orders/:orderId/exceptions",
  },
  /**
   * The invoice service provide access to invoice data,
   * Data delivered is filtered on the current users default
   * customer group and optional access rights.
   */
  invoiceservice: {
    /** GET: fetch invoice with specified internal invoice id */
    invoice: "/invoices/:invoiceId",
    invoices: "/invoices",
    invoiceFile: "/invoices/:invoiceId/document",
    invoicePayments: "/invoices/:invoiceId/payments",
  },
  invoiceaccountservice: {
    /** GET: fetch invoice account with specified invoice account id */
    invoiceaccount: "/invoice-accounts/:invoiceAccountId",
    invoiceraccounts: "/invoice-accounts",
    notes: "/invoice-accounts/:invoiceAccountId/notes",
    tasks: "/invoice-accounts/:invoiceAccountId/tasks",
    ageddebt: "/invoice-accounts/:invoiceAccountId/aged-debt",
    nextInvoiceDate: "/invoice-accounts/:invoiceAccountId/next-invoice-date",
    creditRating: {
      latest: "/invoice-accounts/:invoiceAccountId/creditRating/current",
      search: "/invoice-accounts/:invoiceAccountId/creditRating",
    },
    invoiceAccountCategories: "/invoice-accounts/:invoiceAccountId/categories",
  },
  /**
   * The inventory service provide access to inventory data,
   * Data delivered is filtered on the current users default
   * customer group.
   */
  inventoryservice: {
    /** GET: fetch inventory with specified internal inventory id */
    inventory: "/inventory/:inventoryId",
    inventories: "/inventory",
  },

  /**
   * The numberport service provide access to Number Porting Case data,
   * Data delivered is filtered on the current users default
   * customer group.
   */
  numberportservice: {
    /** GET: fetch case with specified case id */
    case: "/numberport/cases/:caseId",
    cases: "/cases",
  },

  /**
   * The product service provides access to product data,
   * Data delivered is filtered on the current users default
   * customer group and optional access rights.
   */
  productservice: {
    /** GET: fetch product with specified product id */
    product: "/products/:productId",
    products: "/products",
    productSearchPartHistory: "/products/history/:subscriptionId",
    productInvoicePreview: "/products/:productId/preview",
    productOrders: "/products/:productId/orders",
    productOptionsContainer: "/products/:productId/offering-options",
    productLifecycleData: "/products/:productId/lifecycle",
  },

  /**
   * The subscription service provides access to subscription data,
   * Data delivered is filtered on the current users default
   * customer group and optional access rights.
   */
  subscriptionservice: {
    /** GET: fetch product with specified product id */
    subscription: "/subscriptions/:subscriptionId",
    subscriptions: "/subscriptions",
    subscriptionNotes: "/subscriptions/:subscriptionId/notes",
    subscriptionOrders: "/subscriptions/:subscriptionId/orders",
  },

  /**
   * The history service provides access to history entries for the given entity.
   */
  historyservice: {
    history: "/history/entityType/:entityType/:entityId",
  },

  /**
   * The logger service provides the ability to log errors, warnings and information to the billiant event logger.
   */
  loggerservice: {
    logEvent: "/logger/log-event",
  },

  printing: {
    printerQueues: "/printing/queues",
    printerQueue: "/printing/queues/:printerQueueId",
    printerQueueEntries: "/printing/queueEntries",
    printerQueueEntriesCount: "/printing/queueEntries/toPrint",
    printerQueueEntry: "/printing/queueEntries/:printerQueueEntryId",
  },
  myAccount: {
    /* GET - return user */
    myaccount: "/myaccount",
    /* PUT - change profile */
    profile: "/myaccount/profile",
    /* PUT - change preferences */
    preferences: "/myaccount/preferences",
    /* PUT - change password */
    security: "/myaccount/security",
    /* GET - return tasks */
    tasks: "/myaccount/tasks",
    notifications: "/myaccount/notifications",
    bookmarks: "/myaccount/bookmarks",
    bookmark: "/myaccount/bookmarks/:id",

    /**
     * To manage a set of user keys (GET/PUT/DELETE)
     */
    userKeys: "/myaccount/user-keys",

    /**
     * To manage a specific user key (GET/PUT/DELETE)
     */
    userKey: "/myaccount/user-keys/:name",
  },
  config: {
    screen: "/config/screens/:screenId",
    view: "/config/views/:viewId",
    apps: "/config/apps",
    app: "/config/apps/:appConfigId",
    appFooter: "/config/apps/:appConfigId/footer",

    filters: "/config/filters",
    filterValues: "/config/filters/:filterId/values",
    entityTypeFields: "/config/entityType/:entityType/fields",

    icons: "/config/icons",

    widget: "/config/widgets/:widgetId",
    search: "/config/search/:searchId",
    viewWidgets: "/config/views/:viewId/widgets",
    /** POST: Clear server cache */
    clearServerCache: "/config/cache/clear",
    screenUrl: "/config/screen/:tag",
  },
  /**
   * The address service provides access to address data,
   * Data delivered is filtered on the current users default
   * customer group and optional access rights.
   */
  addressservice: {
    /** GET: fetch address with specified internal address id */
    address: "/address/:addressId",
  },
  /**
   * The task service provides access to task data,
   * Data delivered is filtered on the current users default
   * customer group.
   */
  taskservice: {
    /** GET: fetch task with specified internal task id */
    task: "/tasks/:taskId",
    taskNotes: "/tasks/:taskId/notes",
    tasks: "/tasks",
    taskList: {
      tasks: "/tasks/taskLists/:taskListId",
      fetchAvailable: "/tasks/taskLists",
    },
    attachments: "/tasks/:taskId/attachments",
  },
  actions: {
    flows: {
      /** POST /flows/:actionId/create?entityType=:entityType&entityId=:entitiyId */
      create: "/flows/:actionId/create",
      /** PUT /flows/:actionId?reason=[ENTER|LEAVE|CHANGE] */
      evaluate: "/flows/:actionId?reason=:reason",
      /** POST /flows/:actionId */
      perform: "/flows/:actionId",
      /** POST /flows/:actionId/cancel */
      cancel: "/flows/:actionId/cancel",
    },
  },

  /**
   * The payment service provides access to payment data,
   * Data delivered is filtered on the current users default
   * customer group.
   */
  paymentservice: {
    payment: "/payments/:paymentId",
    payments: "/payments",
  },
  /**
   * User service for the Billiant Web API. This service returns user data.
   */
  userservice: {
    user: "/users/:userId",
    users: "/users",
    currentUser: "/users/user",
    customers: "/users/:userId/customers",
    products: "/users/:userId/products",
    userInvoices: "/users/user-invoices",
    userProducts: "/users/user-products",
    userDocumentFile: "/users/user-documents/:documentId/file",
    paymentMethod: "/users/user-payment-method",
  },
  /**
   * Stripe integration service
   */
  stripeservice: {
    createPaymentIntent: "/stripe/payment-intent/:productId/create",
    confirmPaymentIntent: "/stripe/payment-intent/:productId/confirm",
    addPaymentMethod: "/stripe/payment-method/add",
    getPaymentIntentStatus:
      "/stripe/payment-intent/:productId/:paymentIntentId/status",
  },
  /**
   * GoCardless integration service
   */
  gocardlessservice: {
    createBillingRequestFlow: "/gocardless/billing-flow/:productId/create",
    addPayment: "/gocardless/payment/:productId/add",
  },
  /**
   * Web Setup for web app configuration
   */
  webSetupService: {
    tree: "/web-setup/tree/:entityType/:entityId",
    actions: "/web-setup/actions-per-type/:entityType",
  },
} as const;
