import { AddCircle, Clear } from "common/assets/icons";
import { Box, IconButton, TextField, TextFieldProps } from "common/components";
import { useTranslation } from "i18n";
import { forwardRef } from "react";
import { v4 as uuidv4 } from "uuid";

export interface GUIDFieldProps extends Omit<TextFieldProps, "onChange"> {
  /** Sets value on change and when generating a new GUID */
  setValue(guid: string): void;
}

/** GUID field with auto-generation */
export const GUIDField = forwardRef<HTMLInputElement, GUIDFieldProps>(
  ({ setValue, disabled, ...props }, ref) => {
    const { t } = useTranslation(["common"]);
    const hasValue = !!(props?.value as string)?.length;
    return (
      <Box
        display="grid"
        gridTemplateColumns="1fr repeat(2, min-content)"
        alignItems="center"
        gap={(theme) => theme.spacing(0.2)}
      >
        <TextField
          ref={ref}
          {...props}
          disabled={disabled}
          slotProps={{
            inputLabel: {
              required: props.required,
              ...props.slotProps?.inputLabel,
            },
          }}
          onChange={(event) => setValue(event.target.value)}
        />
        <IconButton
          size="small"
          color="primary"
          aria-label={t("common:buttons.generateGUID")}
          onClick={() => setValue(uuidv4().toUpperCase())}
          disabled={disabled}
        >
          <AddCircle fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          aria-label={t("common:buttons.clearGUID")}
          onClick={() => setValue("")}
          disabled={disabled || !hasValue}
        >
          <Clear fontSize="small" />
        </IconButton>
      </Box>
    );
  }
);
