import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InfoOutlined } from "common/assets/icons";
import {
  Box,
  Card,
  CardActionArea,
  cardActionAreaClasses,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  ExpandMore,
  IconButton,
  Tooltip,
  Typography,
} from "common/components";

import { formatCurrency, formatNumberWithUnit } from "common/utils";
import { ProductOfferingOption } from "core/api";
import { LoadingView } from "core/components";
import { useTranslation } from "i18n";
import React, { ReactElement, Suspense, useState } from "react";

interface OptionsCardProps {
  /** The product offering option details. */
  option: ProductOfferingOption;
  /** The index of the current option. */
  index: number;
  /** Indicates if the option is currently selected. */
  selected: boolean;
  /** Function to set the currency value. */
  setCurrency?: (value: string) => void;
  /** Function to set the amount value. */
  setAmount?: (value: number) => void;
  /** Function to set the selected index. */
  setSelectedIndex: (index: number) => void;
}

/**
 * Card used to display product offering options.
 *
 */
export function OptionsCard({
  option,
  selected,
  index,
  setCurrency,
  setAmount,
  setSelectedIndex,
}: OptionsCardProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const monthlyFee = formatCurrency(
    option.totalMonthlyPrice.amountInclTax,
    option.currencyCode
  );

  const perMonth =
    " / " +
    formatNumberWithUnit(1, "month", { unitDisplay: "long" }).replace("1", "");
  const currencyCode = option.currencyCode;

  const [expanded, setExpanded] = useState(false);

  /**
   * Toggles the expanded state.
   * If the component is expanded, it collapses it, and if it is collapsed, it expands it.
   */
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /**
   * Handles the click event on the specified element.
   * If the clicked element is not an excluded node and no text is selected, it calls the handleSelection function.
   *
   * @param event - The mouse event triggered by clicking on an element.
   */
  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    const isClickEvent = event.type === "click";
    if (isClickEvent) {
      const excludedNodes = ["BUTTON", "svg"];
      const clickedNodeName = (event.target as HTMLElement).nodeName;
      const selection = window.getSelection()?.toString() ?? "";

      // Check if the clicked element is not one of the excluded nodes, and no selection of text
      if (!excludedNodes.includes(clickedNodeName) && selection.length === 0) {
        handleSelection();
      }
    }
  };

  /**
   * Handles key press events.
   * Calls the handleSelection function if space key is pressed.
   */
  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    const isSpacebarPressed = event.key === " ";
    if (isSpacebarPressed) {
      handleSelection();
    }
  };

  /**
   * Handles the selection logic.
   * Sets the currency and amount based on certain conditions and updates the selected index.
   */
  const handleSelection = () => {
    if (selected && setCurrency && setAmount) {
      // amount = 0 causes an exception, use minimum allowed (50 c).
      setCurrency("eur");
      setAmount(50);
    } else if (setCurrency && setAmount) {
      setCurrency(currencyCode.toLowerCase());
      setAmount(option.totalBaseUnitPrice.amountInclTax);
    }
    setSelectedIndex(selected ? -1 : index);
  };

  return (
    <Card
      data-cy={"optionsCard"}
      sx={{
        width: 300,
        margin: "3px",
        borderRadius: "1rem",
        ...(selected && {
          outline: "solid 3px",
          outlineColor: (theme) => theme.palette.primary.main,
        }),
      }}
      variant="outlined"
      onClick={(event: React.MouseEvent<HTMLElement>) => handleOnClick(event)}
    >
      <Suspense fallback={<LoadingView />}>
        <Box sx={{ height: 70 }}>
          <CardHeader
            title={option.rule?.name ?? option.productOfferingName}
            subheader={
              <Box
                sx={{
                  maxHeight: 80,
                  overflowY: "auto",
                }}
              >
                {option?.tags?.map((tag, index) => {
                  return (
                    <Chip
                      key={index}
                      label={tag}
                      size="small"
                      sx={{
                        margin: "2px",
                        marginLeft: "1px",
                        marginRight: "0px",
                        background: (theme) => theme.palette.primary.light,
                      }}
                    />
                  );
                })}
              </Box>
            }
            action={
              (option.rule?.externalURL ?? option.externalURL) && (
                <Tooltip title={option.rule?.externalURL ?? option.externalURL}>
                  <IconButton
                    size="small"
                    href={option.rule?.externalURL ?? option.externalURL}
                    target="bssWebProductInfo"
                  >
                    <InfoOutlined data-cy={"infoButton"} />
                  </IconButton>
                </Tooltip>
              )
            }
          />
        </Box>
        <CardActionArea
          onKeyDown={handleKeyPress}
          disableRipple
          sx={{
            "&:hover": {
              [`& .${cardActionAreaClasses.focusHighlight}`]: {
                opacity: 0, // disables the high light when hover with the mouse. Its still there for using the keyboard
              },
            },
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              sx={{ marginTop: "1.7em", marginBottom: "0.9em" }}
              color="text.secondary"
              align="center"
            >
              <b>{monthlyFee}</b> {perMonth}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <ExpandMore
            data-cy={"expandButton"}
            expand={expanded}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {option.rule && (
              <>
                <Typography component="p" data-cy="description">
                  {t("common:description")}
                </Typography>
                <Typography component="p" variant="body2">
                  {option.rule?.description}
                </Typography>
              </>
            )}

            <Typography component="p">
              {t("common:applicablePrices")}
            </Typography>

            {option?.priceDetails?.map((priceDtl, index) => {
              const fee = formatCurrency(
                priceDtl.amountInclTax,
                option.currencyCode
              );
              return (
                <Box
                  key={index}
                  sx={{ display: "grid", gridTemplateColumns: "auto auto" }}
                  data-cy="cardContent"
                >
                  <Typography variant="body2">{priceDtl.chargeName}</Typography>
                  <Typography variant="body2" align="right">
                    {fee}
                  </Typography>
                </Box>
              );
            })}
          </CardContent>
        </Collapse>
      </Suspense>
    </Card>
  );
}
