import {
  DateOrTimeView,
  DateTimePicker,
  DateTimePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { Box } from "common/components";
import { DateAdapter } from "common/utils";

import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "i18n";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";
import {
  getFormDatePickerOnChange,
  getKeyboardInputDateValidator,
  insertDateErrorHint,
  triggerFieldEvaluation,
} from "../formDateUtils";

export interface FormDateTimePickerProps
  extends Omit<
      DateTimePickerProps<Dayjs>,
      "value" | "onChange" | "renderInput" | "label" | "defaultValue"
    >,
    FormControlledFieldProps {
  helperText?: string;
  inputFormat?: string;
  "data-cy"?: string;
}

/** Form datetime picker */
export const FormDateTimePicker = withFormController<FormDateTimePickerProps>(
  ({
    fieldName,
    helperText,
    inputFormat = "L LTS",
    renderState,
    "data-cy": dataCy,
    ...props
  }) => {
    const { t } = useTranslation(["common"]);
    const {
      field: { value, name, onChange, ...field },
      fieldState: { error },
      gridColumn,
      isDisabled,
      setExtendedRules,
    } = renderState;

    const context = useFormContext();
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      setExtendedRules?.({
        validate: {
          checkDateTime: getKeyboardInputDateValidator(
            inputFormat,
            t("common:forms.date.dateTimeError"),
            props.required,
            t("common:forms.fieldIsMandatory")
          ),
        },
      });
    }, [setExtendedRules, t, inputFormat, props.required]);

    const parsedDate = value?.event
      ? dayjs(value.event)
      : dayjs(value ?? new Date());

    const pickerViews: DateOrTimeView[] =
      inputFormat === "L LT"
        ? ["year", "month", "day", "hours", "minutes"]
        : ["year", "month", "day", "hours", "minutes", "seconds"];

    return (
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Box data-cy={dataCy} sx={{ display: "contents" }}>
          <DateTimePicker
            {...props}
            {...field}
            views={pickerViews}
            format={inputFormat}
            value={parsedDate}
            onChange={getFormDatePickerOnChange(onChange)}
            onError={() => triggerFieldEvaluation(name, value, context.trigger)}
            disabled={isDisabled}
            slotProps={{
              openPickerButton: { "data-cy": "datePickerButton" } as any,
              textField: {
                sx: { minWidth: "190px", gridColumn },
                name: fieldName,
                error: !!error,
                inputRef,
                helperText: insertDateErrorHint(
                  error,
                  helperText,
                  inputRef.current?.placeholder
                ),
                InputLabelProps: {
                  required: props.required,
                },
              },
            }}
          />
        </Box>
      </LocalizationProvider>
    );
  }
);
