import { FormHelperText } from "@mui/material";
import { MuiTelInput, MuiTelInputCountry } from "mui-tel-input";

import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export type FormPhoneNumberFieldProps = Omit<
  FormControlledFieldProps,
  "variant"
> & {
  renderValue?: (value: any) => any;
  country: string;
  label: string;
  validationRules: FormControlledFieldProps["validationRules"];
  helperText?: string;
};

/**
 * Adds form handling for Phone Number field.
 *
 * @param fieldName Static string used for the unique ids of the inner components.
 * @param control Form control.
 * @param validationRules Optional field validation rules
 * @param helperText Optional helper text to display below the input field.
 * @param country The country code for the phone number input.
 * @param label The label for the phone number input field.
 * @param props Any remaining properties.
 */

export const FormPhoneNumberField =
  withFormController<FormPhoneNumberFieldProps>(
    ({
      fieldName,
      renderValue,
      renderState,
      validationRules,
      helperText,
      country,
      label,
      ...props
    }) => {
      const {
        field: { value, ...field },
        fieldState: { error },
      } = renderState;

      const defaultCountry =
        (country.toUpperCase() as MuiTelInputCountry) || undefined;

      return (
        <>
          <MuiTelInput
            defaultCountry={defaultCountry}
            continents={["EU"]}
            forceCallingCode
            disableFormatting
            label={label}
            data-cy={fieldName}
            value={renderValue?.(value) ?? value ?? ""}
            slotProps={{
              input: {
                error: !!error,
              },
            }}
            {...props}
            {...field}
          />
          <FormHelperText error>{error?.message ?? helperText}</FormHelperText>
        </>
      );
    }
  );

export default FormPhoneNumberField;
