export default {
  urlNotFound: "Die Seite wurde nicht gefunden",
  notFoundOrNotAvailable:
    "Die Seite, auf die du zugreifen möchtest, existiert nicht oder wurde entfernt",
  yourApplications: "Deine Anwendungen",
  homePage: "Anwendungen",
  applications: "Deine Anwendungen",
  customerCare: "Kundenbetreuung",
  printerQueue: "Druckerwarteschlange",
  loading: "Lädt...",
  loadingApp: "Webanwendung lädt...",
  logout: "Ausloggen",
  switchTo: "Wechsel zu",
  yourChangesAreSaved: "Die Änderungen wurden gespeichert",
  filters: "Filter",
  configuration: "Konfiguration",
  rowsperpage: "Reihen pro Seite",
  customerGroup: "Kundengruppe",
  help: "Hilfe",
  notifications: "Meldungen",
  dashboard: "Dashboard",
  documentation: "Dokumentation",
  downloadingDocument: "Dokument herunterladen...",
  loadingDocument: "Dokument wird geladen...",
  noDocumentAvailable:
    "Der Zugriff auf das angeforderte Dokument ist entweder eingeschränkt oder vorübergehend nicht möglich",
  nextPage: "Nächste Seite",
  previousPage: "Vorige Seite",
  downloadingFile: "{{fileName}} wird heruntergeladen",
  downloadFile: "{{fileName}} herunterladen",
  fileName: "Dateiname",
  about: "Über diese Anwendung",
  version: "Version: {{version}}",
  errorNoIdentityProvider:
    "Es konnte kein Identitätsanbieter aus der Datenbank abgerufen werden.",
  name: "Name",
  customerName: "Kundenname",
  status: "Status",
  address: "Adresse",
  invoices: "Rechnungen",
  inventories: "Bestandsposten",
  type: "Typ",
  products: "Produkten",
  showHideButton: {
    show: "Komponente anzeigen",
    hide: "Komponente ausblenden",
  },
  bookmarks: {
    noFoundBookmarks: "Keine {{entityType}} lesezeichen vorhanden",
    noFoundEntityBookmarks: "Keine lesezeichen vorhanden",
    addToBookmarks: "Zu den Lesezeichen hinzufügen",
    removeFromBookmarks: "Aus den Lesezeichen entfernen",
  },
  entityTypeName: {
    account_one: "Account",
    account_other: "Accounts",
    address_one: "Adresse",
    address_other: "Adressen",
    adjustment_one: "Berichtigung",
    adjustment_other: "Berichtigungen",
    webApplication_one: "Anwendung",
    webApplication_other: "Anwendungen",
    customer_one: "Kunde",
    customer_other: "Kunden",
    document_one: "Dokument",
    document_other: "Dokumenten",
    invoice_one: "Rechnung",
    invoice_other: "Rechnungen",
    inventory_one: "Bestandspost",
    inventory_other: "Bestandsposten",
    message_one: "Nachricht",
    message_other: "Nachrichten",
    note_one: "Notiz",
    note_other: "Notizen",
    npcase_one: "Portierung Antrag",
    npcase_other: "Portierung Antragen",
    payment_one: "Zahlung",
    payment_other: "Zahlungen",
    product_one: "Produkt",
    product_other: "Produkten",
    productOffering_one: "Produktangebot",
    productOffering_other: "Produktangebote",
    repayment_one: "Rückzahlung",
    repayment_other: "Rückzahlungen",
    subscription_one: "Abonnement",
    subscription_other: "Abonnemente",
    task_one: "Aufgabe",
    task_other: "Aufgaben",
    tasklist_one: "Aufgabenliste",
    tasklist_other: "Aufgabenlisten",
    user_one: "Nutzer",
    user_other: "Nutzer",
    nrdbClient_one: "Kunde",
    nrdbClient_other: "Kunden",
    nrdbConfig_one: "Konfiguration",
    nrdbConfig_other: "Konfigurationen",
    nrdbMessage_one: "Mitteilung",
    nrdbMessage_other: "Mitteilungen",
    nrdbNumber_one: "Telefonnummer",
    nrdbNumber_other: "Telefonnummern",
    nrdbProxy_one: "Proxy",
    nrdbProxy_other: "Proxies",
    order_one: "Auftrag",
    order_other: "Aufträge",
    webWidget_one: "Komponente",
    webWidget_other: "Komponenten",
    webTable_one: "Tabelle",
    webTable_other: "Tabellen",
    webTableOption_one: "Tabellenkonfiguration",
    webTableOption_other: "Tabellenkonfigurationen",
    webTableFieldDefinition_one: "Tabellenspalte",
    webTableFieldDefinition_other: "Tabellenspalten",
    webView_one: "Anzicht",
    webView_other: "Anzichten",
    webScreen_one: "Bildschirm",
    webScreen_other: "Bildschirme",
    webFlow_one: "Ablauf",
    webFlow_other: "Abläufe",
    webStep_one: "Schritt",
    webStep_other: "Schritte",
    webAppConfig_one: "Anwendungskonfiguration",
    webAppConfig_other: "Anwendungskonfigurationen",
    webNavigationGroup_one: "Navigationsgruppe",
    webNavigationGroup_other: "Navigationsgruppen",
    webSearchFilter_one: "Suchfilter",
    webSearchFilter_other: "Suchfilter",
    webAction_one: "Aktion",
    webAction_other: "Aktionen",
  },
  dateGroupTitle: {
    today: "Heute",
    last_week: "Während der letzten Woche",
    last_month: "Während des letzten Monats",
    earlier: "Früher",
  },
  sideBar: {
    show: "Anzeigen",
    hide: "Ausblenden",
    showTooltip: "Die Navigationsleiste anzeigen",
    hideTooltip: "Die Navigationsleiste ausblenden",
  },
  loggedOut: {
    message: "Du wurdest abgemeldet",
    gotoLogin: "Gehe zur Loginseite",
  },
  editCustomerNote: {
    noteText: "Notiztext",
    status: "Stimmung des Kunden",
  },
  properties: "Eigenschaften",
  noAvailableData: "Keine Daten verfügbar",
  noValue: "<keinen Wert>",
  noValueSelected: "<Keins>",
  deleteSelected: "Ausgewählte Elemente löschen?",
  copyFrom: "Kopieren von",
  buttons: {
    accept: "Annehmen",
    cancel: "Abbrechen",
    continue: "Fortsetzen",
    clear: "Löschen",
    close: "Schliessen",
    copy: "Kopieren",
    copyFrom: "Kopieren von der {{addressType}}adresse",
    create: "Erstellen",
    decline: "Ablehnen",
    delete: "Löschen",
    dismiss: "Abweisen",
    download: "Herunterladen",
    ok: "Ok",
    print: "Drucken",
    refreshPageData: "Seitendaten aktualisieren",
    reset: "Zurücksetzen",
    save: "Speichern",
    update: "Aktualisieren",
    search: "Suchen",
    undo: "Rückgängig machen",
    moreDetails: "Weitere Details",
    showAll: "Alle anzeigen",
    showAllHiddenAfter: "Ergebnisse für {{search}} in {{entityType}}",
    showCountMore: "Zeige {{count}} weitere",
    showPDF: "PDF-Datei anzeigen",
    preview: "Vorschau",
    previewHiddenAfter: "next invoice",
    next: "Weiter",
    back: "Zurück",
    finish: "Fertigstellen",
    generateGUID: "GUID erzeugen",
    clearGUID: "GUID löschen",
    generatePINCode: "PIN erzeugen",
    clearPINCode: "PIN Code löschen",
    toggleVisibility: "Sichtbarkeit des Passworts umschalten",
    currentUserAccount: "Konto des aktuellen Nutzers",
    accountMenu: "Konto-Menü",
    applicationSwitcher: "Anwendungswechsler",
    backTo: "Zurück zum/zur vorherigen {{entityType}}",
    backToTooltip: "Zurück zu",
  },
  forms: {
    fieldIsMandatory: "Dieses Feld ist ein Pflichtfeld",
    mandatory: "{{label}} (Pflichtfeld)",
    minLength: "Dieses Feld benötigt mindestens {{count}} Zeichen",
    maxLength: "Dieses Feld erlaubt maximal {{count}} Zeichen",
    lengthBetween:
      "Dieses Feld benötigt zwischen {{minLength}} und {{maxLength}} Zeichen",
    lengthExactly: "Dieses Feld benötigt genau {{count}} Zeichen",
    pattern: "Der Feldwert muss dem Muster {{pattern}} entsprechen",
    formatShortSocialSecNo: "XX DDMMYY A YY Z",
    formatLongSocialSecNo: "XX DDMMYY A YY Z",
    date: {
      dateTimeError:
        "Das gewählte Datum oder die Uhrzeit ist ungültig (Hinweis: {{hint}})",
      dateError: "Das gewählte Datum ist ungültig  (Hinweis: {{hint}})",
      timeError: "Die gewählte Uhrzeit ist ungültig  (Hinweis: {{hint}})",
    },
    tableSelectionIsMandatory: "Es muss eine Reihe ausgewählt werden",
    jsonField: "Dieses Feld muss eine gültige JSON-Struktur enthalten",
    translations: {
      hint: "Übersetzungen öffnen",
      title: "Übersetzungen",
      contentText: "Geben Sie die Übersetzungen für {{label}} ein",
    },
    iconSelector: {
      searchIcons: "Symbol suchen...",
      selectIcon: "Wählen Sie ein Symbol aus",
      noIcon: "Kein Symbol",
      openIconSelector: "Symbolwähler öffnen",
      icon: "Symbol,",
    },
  },
  table: {
    rowActionMenu: {
      tooltip: "Aktionen für die gewählte Reihe",
      ariaLabel: "Aktionsmenü",
    },
    multiRowActionMenu: {
      tooltip: "Aktionen für die gewählten Reihen",
      ariaLabel: "Aktionsmenü",
    },
    expandRow: "Reihe uasklappen",
    defaultError: "Beim Laden der Tabelle ist ein Fehler aufgetreten",
  },
  cookieText:
    "Billiant verlangt, dass die unbedingt notwendigen Cookies vom Browser akzeptiert werden, um die Verwaltung der Anmeldung und der Benutzereinstellungen zu ermöglichen. Die Webanwendung funktioniert nicht ohne die unbedingt erforderlichen Cookies.",
  snackbarMessageOccurredMultipleTimes: "({{times}} Mal aufgetreten)",
  fetchingData: "Daten werden abgerufen",
  keyboardShortcuts: "Tastenkürzel",
  settings: "Einstellungen",
  settingsMenu: {
    settingsMenu: "Einstellungen Menü",
    allowDnD: "Ziehen und ablegen erlauben",
  },
  navigation: "Navigation",
  filter: {
    hideFilter: "Filter ausblenden",
    showFilter: "Filter anzeigen",
  },
  searchFilter: {
    currency: "Währung",
    selectCurrency: "Währung wählen",
    textFrom: "{{text}} (von)",
    textTo: "{{text}} (bis)",
    fromValue: "Von {{value}}",
    toValue: "Bis {{value}}",
    searchText: "Suchbegriff",
    today: "Heute",
  },
  ofTotal: "von {{count}}",
  zoomIn: "Vergrößern",
  zoomOut: "Verkleinern",
  approveOrders: "Auftrag genehmigen",
  adjustmentDate: "Berechtigungsdatum",
  chargeType: "Gebührenart",
  generalLedgerInfo: "Informationen zum Hauptbuch",
  taxBundleInfo: "Informationen zur Steuerregelung",
  adjustmentDescription: "Beschreibung der Berichtigung",
  adjustmentDescriptionHelper:
    "Wähle oder gib eine Beschreibung ein, die auf der Rechnung erscheinen soll.",
  decision: "Beschluss",
  earliestInvoiceDate: "Frühestes Rechungsdatum",
  enableImmediateInvoicing: "Sofortige Rechnungsstellung ermöglichen",
  inputAmountInclVat: "Betrag inkl. {{vatRate}} MwSt. eingeben",
  amountExclVat: "Betrag (ohne {{vatRate}} MwSt.)",
  amountVat: "Mehrwertsteuer (MwSt.)",
  amountInclVat: "Betrag (inkl. {{vatRate}} MwSt.)",
  amountsInclTax: "Anzeige/Eingabe von Beträgen inklusive Steuer",
  effectiveDate: "Wirksamkeitsdatum",
  overrideBinds: "Vertragliche Bindungen/Kündigungsfrist aufheben",
  reason: "Grund",
  supressFees: "Vertragsverletzungsgebühren abschaffen",
  categoryWidget: {
    noCategoriesFound: "Keine Kategorien gefunden",
    noCategoriesFoundHelper: "Dieser Kunde hat noch keine Kategorien",
    missingScreenEntity:
      "Aktueller Bildschirm vermisst eine {{entityType}}-Entität",
    missingScreenEntityHelper:
      "Das Widget konnte keine {{entityType}}-Entität finden, die mit dem aktuellen Bildschirm verbunden ist",
  },
  historyWidget: {
    message: "Nachricht",
    oldValue: "Alte Wert",
    newValue: "Neue Wert",
    orderId: "Auftragsnummer",
    dateLastPost: "Datum der letzten Änderung: {{date}}",
  },
  dayOfMonth: "Tag im Monat",
  days: "Tagen",
  amount: "Betrag",
  amountWithCurrency: "Betrag ({{currencyCode}})",
  current: "Aktueller",
  totalAmount: "Gesamtbetrag {{amount}}",
  createdBy: "Erstellt von: {{createdBy}}",
  scoreWidget: {
    score: "Bewertung {{score}}",
    noScore: "Keine Bewertung",
  },
  reminderXSent: "{{number}}. Mahnung verschickt",
  titleIsMissing: "Titel fehlt",
  noChildWidget:
    "Keine passende Komponente gefunden. Entitätstyp: {{EntityType}}, entityTypeID: {{number}}",
  descriptionIsMissing: "Die Beschreibung fehlt",
  noResultsFound: "Keine Ergebnisse vorhanden",
  alerts_one: "{{count}} Warnung",
  alerts_other: "{{count}} Warnungen",
  unknownEntity: "Unbekannte Entität",
  breadcrumb: "Brotkrümel",
  selectAll: "Alle auswählen",
  items_one: "{{count}} Element",
  items_other: "{{count}} Elemente",
  appNameLogo: "{{appName}} Logo",
  yes: "Ja",
  no: "Nein",
  choose: "Wählen",
  identifier: "Bezeichner",
  score: "Bewertung",
  check: "Prüfen",
  creditScore: "Kreditwürdigkeit",
  creditStatus: "Kreditwürdigkeit-Status",
  creditRatingStatus: {
    notChecked: "Nicht geprüft",
    creditOk: "Gut",
    creditWarning: "Grenzwertig",
    creditNotOk: "Schlecht",
  },
  noTaskEntity: "Es gibt keine Entität, die mit der Aufgabe verbunden ist",
  productOfferingOptions: {
    availableRenewalOptions:
      "Es sind {{optionsLength}} Erneuerungsoptionen verfügbar.",
    noRenewalOptions:
      "Es gibt keine Erneuerungsoptionen. Das Produkt ist nicht aktiv oder hat Vertragsbindungen.",
    payWithCurrent: "Mit der aktuellen Zahlungsweise bezahlen: {{current}}",
    payWithMethod: "Zahlen mit {{method}}",
    readAndApprove: "Ich habe die Allgemeinen Geschäftsbedingungen",
    termsAndConditions: "gelesen und stimme ihnen zu.",
    payAndRenew: "Erneuern und {{amountToPay}} zahlen",
  },
  description: "Beschreibung",
  applicablePrices: "Geltende Preise",
  orderList: {
    configError: {
      name: "Webkonfigurationsfehler",
      description:
        "OrderListWidget benötigt eine TableConfig, die eine einzelne TableOption für den Entitätstyp ORDER enthält",
    },
  },
  orderCard: {
    completionDate: "Ausgeführt: ",
    orderDate: "Bestellt: ",
  },
  subject: "Betreff",
  messageText: "Inhalt der Nachricht",
  productLifeCycle: {
    periods: {
      bind: {
        label: "Vertragsbindung",
        description:
          "Das Produkt kann nicht vor dem frühesten Vertragsbeendigungsdatum gekündigt werden, außer es werden Vertragsstrafen verhängt.",
        before: "VOR Beginn der Vertrags-bindung",
        within: "INNERHALB der Vertragsbindung",
        after: "NACH Ablauf der Vertragsbindung",
      },
      regret: {
        label: "Bedauernsperiode",
        description:
          "Das Produkt kann mit sofortiger Wirkung gekündigt werden, ohne dass eine Vertragsstrafe verhängt wird.",
        within: "INNERHALB der Bedauernsperiode",
      },
      notice: {
        label: "Kündigungsfrist",
        description: {
          beforeLatestNoticeDate:
            "Das Produkt kann zum Ende der Vertragsbindung gekündigt werden, vorausgesetzt, die Kündigung wird VOR dem spätesten Kündigungstermin beantragt.",
          withAdjustment:
            "Das Produkt kann zum frühesten Vertragsbeendigungsdatum gekündigt werden, vorausgesetzt, die Kündigung wird VOR dem spätesten Kündigungstermin beantragt.",
          withoutAdjustment:
            "Das Produkt kann zum frühesten Vertragsbeendigungsdatum gekündigt werden, vorausgesetzt, die Kündigung wird HEUTE beantragt.",
        },
        within: "NACH dem spätesten Kündigungsdatum vom Vertragsbindung",
      },
    },
    marks: {
      bindStart: "Startdatum der Vertrags-bindung",
      bindEnd: "Enddatum der Vertrags-bindung",
      latestRegret: "Letzter Tag der Bedauerns-periode",
      latestNotice: "Spätestes Kündigungs-termin",
      noticeDateWithinBind: "Spätestes Kündigungs-termin der Vertragsbindung",
      earliestTermination: "Frühestes Vertrags-beendigungs-datum",
    },
    currentDateIs: "Aktuelles Datum ist ",
    today: "HEUTE",
  },
  noItemSelected: "Es wurde keine Auswahl vorgenommen",
  noActionMatchingSelection: "Keine Aktion entspricht der aktuellen Auswahl",
  webSetup: {
    newName: "Name",
    newPath: "Pfad",
    copyChildren: "Folgenden Elementen duplizieren",
    visibility: "Sichtbarkeit",
  },
} as const;
