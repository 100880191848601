/** field variants
 * Web model for enum from java.
 */
export enum FieldVariant {
  /**
   * Fixed field (a column)
   */
  FIXED = "FIXED",
  /**
   * Dynamic field (from key = value) prop, managed in Billiant by a panel item.
   */
  DYNAMIC = "DYNAMIC",
  /**
   * Piggyback field injected by API.
   */
  PIGGYBACK = "PIGGYBACK",
  /**
   * Translatable property strings.
   */
  TRANSLATABLE_TEXT = "TRANSLATABLE_TEXT",
}
