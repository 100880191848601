import { TextField, TextFieldProps } from "@mui/material";
import { prettyPrintJson } from "common/utils";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { combineSx } from "styles/theme";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export type FormJsonTextFieldProps = Omit<TextFieldProps, "variant"> &
  FormControlledFieldProps & {
    renderValue?: (value: any) => any;
  };

/**
 * Form component for editing JSON fields in a pretty-printed format.
 *
 * @params Takes the same parameters as a regular FormTextField
 */
export const FormJsonTextField = withFormController<FormJsonTextFieldProps>(
  ({
    fieldName,
    helperText,
    renderValue,
    renderState,
    ...props
  }): ReactElement => {
    const { t } = useTranslation(["common"]);
    const {
      field: { value, ...field },
      fieldState: { error },
      isDisabled,
      gridColumn,
      setExtendedRules,
    } = renderState;

    useEffect(() => {
      setExtendedRules({
        validate: {
          externalValidation: (value: string) => {
            try {
              JSON.parse(value);
            } catch (error: any) {
              return error.message;
            }

            return true;
          },
        },
      });
    }, [setExtendedRules, t]);

    return (
      <TextField
        defaultValue={prettyPrintJson(value) ?? value ?? ""}
        error={!!error}
        fullWidth
        slotProps={{
          inputLabel: {
            required: props.required,
          },
        }}
        {...props}
        {...field}
        helperText={error ? error.message : helperText}
        sx={combineSx({ gridColumn }, props.sx)}
      />
    );
  }
);
