import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  InputLabelProps,
  SelectChangeEvent,
} from "common/components";
import { ForwardedRef, ReactNode, forwardRef } from "react";
import {
  SelectOption,
  UnlabeledSelect,
  UnlabeledSelectProps,
} from "../UnlabeledSelect";

export interface LabeledSelectProps<TOption extends SelectOption = SelectOption>
  extends UnlabeledSelectProps<TOption> {
  label?: ReactNode;
  "data-cy"?: string;
  fullWidth?: boolean;
  InputLabelProps?: InputLabelProps;
  FormControlProps?: FormControlProps;
  helperText?: string;
  value?: string | number;
  onChange?: (event: SelectChangeEvent<TOption>) => void;
}

/** Labeled select field */
export const LabeledSelect = forwardRef(
  <TOption extends SelectOption = SelectOption>(
    {
      label,
      "data-cy": dataCy,
      fullWidth,
      InputLabelProps: inputLabelProps,
      FormControlProps: formControlProps,
      helperText,
      ...props
    }: LabeledSelectProps<TOption>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const labelId = formatSelectId(String(label), "label");
    const selectId = formatSelectId(String(label), "input");
    delete props.ref;

    return (
      <FormControl fullWidth={fullWidth} {...formControlProps}>
        <InputLabel id={labelId} {...inputLabelProps} required={props.required}>
          {label}
        </InputLabel>
        <UnlabeledSelect
          id={selectId}
          labelId={labelId}
          label={label}
          {...props}
          ref={ref}
          data-cy={
            dataCy ?? label?.toString().replaceAll(" ", "") ?? "unlabeledSelect"
          }
        />
        {helperText && (
          <FormHelperText error={props.error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

function formatSelectId(value: string, valueVariant: string) {
  return value.toLowerCase().replaceAll(" ", "-").concat(`-${valueVariant}`);
}
