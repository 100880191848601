import { Search } from "common/assets";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormTextField,
  Grid2,
  OptionsCard,
  Pagination,
} from "common/components";
import { ProductOfferingOption } from "core/api";
import { createWildcardRegex, NoListData } from "core/components";
import { useTranslation } from "i18n";
import {
  ChangeEvent,
  forwardRef,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { theme } from "styles/theme";

interface SearchableOptionsCardFieldProps {
  /** Form control */
  control: any;
  /** List with product offering options */
  options: ProductOfferingOption[];
  /** The selected index. */
  selectedIndex: number;
  /** Function to set the selected index. */
  setSelectedIndex: (index: number) => void;
  /** If an error according to the validation. Will display error text if true. */
  error?: boolean;
}

/**
 * Field with FormControl that displays OptionsCards with search and pagination.
 *
 */
export const SearchableOptionsCardField = forwardRef<
  HTMLDivElement,
  SearchableOptionsCardFieldProps
>(
  (
    {
      options,
      control,
      selectedIndex,
      setSelectedIndex,
      ...props
    }: SearchableOptionsCardFieldProps,
    ref
  ): ReactElement => {
    const { t } = useTranslation(["product"]);
    const [page, setPage] = useState(1);
    // Search query state for filtering
    const [searchQuery, setSearchQuery] = useState<string>("*");
    // items to display per page
    const pageSize = 6;
    const formContext = useFormContext();
    const [hasFiltered, setHasFiltered] = useState(false);

    const handlePageChange = (_event: ChangeEvent<unknown>, value: number) => {
      setPage(value);
    };

    // This is just the input, it doesn't affect filtering until search is clicked
    const searchValue: string = formContext.watch("searchField") ?? "*";

    // Filter options based on searchQuery (set when search button is clicked)
    const filteredOptions = options?.filter((option) => {
      if (searchQuery === "*") {
        return true;
      }

      const optionName = option.rule?.name?.toLowerCase() || "";

      // If the searchQuery contains "*", use it as a wildcard for partial matching
      if (searchQuery.includes("*")) {
        const searchPattern = createWildcardRegex(searchQuery);
        return searchPattern.test(optionName);
      }

      return optionName.toLowerCase() === searchQuery.toLowerCase();
    });

    // Paginate options after filtering
    const paginatedOptions = filteredOptions?.slice(
      (page - 1) * pageSize,
      page * pageSize
    );
    const numPages = Math.ceil(filteredOptions?.length / pageSize);

    /**
     * Handles the search button click event.
     * Updates the `searchQuery` state with the current `searchValue`,
     * resets pagination to the first page, and marks the search as filtered.
     * Optionally clears the selection based on the `autoSearch` parameter.
     *
     * @param autoSearch - Indicates whether the search is triggered automatically.
     *
     */
    const handleSearchClick = (autoSearch = false) => {
      setSearchQuery(searchValue); // Set searchQuery to the value in the search input
      setPage(1);
      setHasFiltered(true);

      if (!autoSearch || options?.length === 0) {
        setSelectedIndex(-1);
      }
    };

    // auto search when first loading the component
    useEffect(() => {
      handleSearchClick(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const searchField = (
      <FormTextField
        control={control}
        defaultValue={searchValue}
        fieldName={"searchField"}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSearchClick();
          }
        }}
        data-cy="formSearchField"
        sx={{ minWidth: 320 }}
      />
    );

    return (
      <FormControl {...props} ref={ref}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: theme.spacing(1),
            marginBottom: theme.spacing(0.5),
            alignItems: "center",
          }}
        >
          {searchField}
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSearchClick()}
            data-cy="formSearchButton"
            sx={{ height: 36 }}
          >
            <Search />
          </Button>
        </Box>

        {!paginatedOptions || paginatedOptions.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: theme.spacing(1),
            }}
          >
            <NoListData variant="data" />
          </Box>
        ) : (
          hasFiltered && (
            <>
              <Grid2 container spacing={1}>
                {paginatedOptions?.map((option) => {
                  // Calculate the global index relative to the original options array
                  const globalIndex = options.indexOf(option);

                  return (
                    <Grid2 key={globalIndex}>
                      <OptionsCard
                        option={option}
                        index={globalIndex}
                        selected={selectedIndex === globalIndex}
                        setSelectedIndex={setSelectedIndex}
                      />
                    </Grid2>
                  );
                })}
              </Grid2>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  marginTop: theme.spacing(0.5),
                }}
              >
                {props.error && options.length > 0 && (
                  <FormHelperText
                    error={props.error}
                    sx={{ position: "absolute", left: 0 }}
                  >
                    {t("product:productOfferingSelectionIsMandatory")}
                  </FormHelperText>
                )}

                <Pagination
                  count={numPages ?? 0}
                  page={page}
                  onChange={handlePageChange}
                />
              </Box>
            </>
          )
        )}
      </FormControl>
    );
  }
);
