import { TextField, TextFieldProps } from "common/components";
import { formatNumber } from "common/utils";
import { forwardRef, KeyboardEvent, ReactElement } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

export interface NumberFieldProps
  extends Omit<NumberFormatProps<TextFieldProps>, "onChange"> {
  /** Field value */
  value?: string | number | undefined;
  /** Name of the field */
  fieldName: string;
  /** Label for the field */
  label: string;
  /** data-cy value  */
  dataCy?: string;
  /** Returns the fields name and value */
  onChange?(value: string): void;
  /** Function for performing a search when pressing enter in the field */
  onKeyPress?(event: KeyboardEvent<HTMLDivElement>): void;
}

/**
 * Number component for displaying formatted inputs
 * with localized thousand separator and comma sign
 */
export const NumberField = forwardRef<
  NumberFormat<TextFieldProps>,
  NumberFieldProps
>(
  (
    {
      value,
      fieldName,
      label,
      dataCy,
      onChange,
      onKeyPress,
      ...props
    }: NumberFieldProps,
    ref
  ): ReactElement => {
    // get separator from locale formatted input
    const decimalSeparator = formatNumber(1.1)[1];

    // get separator from locale formatted input
    const thousandSeparator = formatNumber(1000, { useGrouping: true }).split(
      ""
    )[1];

    return (
      <NumberFormat
        ref={ref}
        data-cy={dataCy}
        name={fieldName}
        value={value}
        displayType="input"
        isNumericString
        decimalSeparator={decimalSeparator}
        thousandSeparator={thousandSeparator}
        onValueChange={({ value: localValue }) => {
          onChange?.(String(localValue));
        }}
        label={label}
        customInput={TextField}
        onKeyDown={onKeyPress}
        {...props}
      />
    );
  }
);
