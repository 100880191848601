import { SelectAll } from "@mui/icons-material";
import {
  Icon,
  IconButton,
  IconSelectorDialog,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
} from "common/components";
import { useTranslation } from "i18n";
import { ReactElement, useState } from "react";
import { combineSx } from "styles/theme";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export type FormIconSelectorFieldProps = Omit<TextFieldProps, "variant"> &
  FormControlledFieldProps & {
    renderValue?: (value: any) => any;
  };

/**
 * Form component for handling editing of Icons via a selector.
 */
export const FormIconSelectorField =
  withFormController<FormIconSelectorFieldProps>(
    ({
      placeholder,
      defaultValue,
      fieldName,
      helperText,
      renderValue,
      renderState,
      ...props
    }): ReactElement => {
      const {
        field: { value, onChange, ...field },
        fieldState: { error },
        isDisabled,
        gridColumn,
      } = renderState;

      const [open, setOpen] = useState(false);

      const openCloseDialog = () => {
        setOpen(!open);
      };

      const saveIcon = (newIcon: any) => {
        openCloseDialog();
        onChange(newIcon);
      };

      const { t } = useTranslation(["common"]);

      return (
        <>
          <TextField
            placeholder={placeholder}
            defaultValue={defaultValue}
            fullWidth
            value={value}
            {...field}
            {...props}
            slotProps={{
              input: {
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>{value}</Icon>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={t("common:forms.iconSelector.openIconSelector")}
                    >
                      <IconButton size="small" onClick={openCloseDialog}>
                        <SelectAll />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              },
            }}
            helperText={helperText}
            sx={combineSx({ gridColumn }, props.sx)}
          />
          <IconSelectorDialog
            saveIcon={saveIcon}
            open={open}
            openCloseDialog={openCloseDialog}
            value={value}
          />
        </>
      );
    }
  );
