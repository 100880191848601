import {
  FormCheckBox,
  FormDateTimePicker,
  FormSelect,
} from "common/components";
import { addFlowStepPrefix } from "common/utils";
import {
  ChangeEntityStatusFlowData,
  ChangeEntityStatusFlowStep,
} from "core/api";
import dayjs from "dayjs";
import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { FlowStepComponentProps } from "../models";
import { StyledStepContentWrapper } from "../steps.styles";

export type ChangeEntityStatusProps = FlowStepComponentProps<
  ChangeEntityStatusFlowStep,
  ChangeEntityStatusFlowData
>;

/** A component that allows the user to change the status reason of an entity
 *  and also set some order attributes (effective date, approval). */
export function ChangeEntityStatus({
  control,
  data,
  flowStep,
}: ChangeEntityStatusProps): ReactElement {
  const { t } = useTranslation(["common"]);

  const { approved, effectiveDate } = data.orderAttributes;
  const { statusReasonId, applicableReasons } = data;
  const simpleMode = flowStep.config.simpleMode;

  return (
    <StyledStepContentWrapper>
      {!simpleMode && (
        <FormSelect
          control={control}
          label={t("common:reason")}
          fieldName={addFlowStepPrefix(flowStep, "statusReasonId")}
          required
          defaultValue={statusReasonId}
          validValues={applicableReasons}
        />
      )}
      <FormDateTimePicker
        control={control}
        label={t("common:effectiveDate")}
        fieldName={addFlowStepPrefix(flowStep, "effectiveDate")}
        defaultValue={effectiveDate}
        minDate={
          effectiveDate?.length > 0 &&
          flowStep.config.allowedToBackDate === false
            ? dayjs(effectiveDate)
            : undefined
        }
        required
        inputFormat="L LT"
      />
      {!simpleMode && (
        <FormCheckBox
          control={control}
          fieldName={addFlowStepPrefix(flowStep, "approved")}
          label={t("common:approveOrders")}
          defaultValue={approved}
          disabled={!flowStep.config.allowedToApprove}
        />
      )}
    </StyledStepContentWrapper>
  );
}
