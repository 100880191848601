import { Search } from "common/assets/icons";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormMultipleSelect,
  FormTextField,
  Typography,
} from "common/components";
import {
  EntityType,
  TableConfig,
  TaggedTableData,
  useFetchFieldConfig,
} from "core/api";
import {
  BilliantTable,
  BilliantTableProps,
  SearchFilterPanel,
  TableSelectionVariant,
  WebSetupToolbar,
  createDBTableCellWithoutOnClick,
  formatEntityUrl,
  useTableColumnContent,
} from "core/components";
import { useEntityTitle } from "core/hooks";
import { debounce, isEqual } from "lodash";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import {
  FormControlledFieldProps,
  WithFormControllerProps,
  withFormController,
} from "../FormController";
import { useTableSearch } from "./useTableSearch";

export interface FormTableProps<TData>
  extends FormControlledFieldProps,
    Omit<
      BilliantTableProps<TData>,
      "defaultValue" | "tableItems" | "columns" | "tableSettingsKey"
    > {
  /** API url to fetch table data */
  apiUrl?: string;
  /** Table items */
  tableItems?: TData[];
  /** Table configuration */
  tableConfig: TableConfig;
  /** Table selection variant */
  selectionVariant: TableSelectionVariant;
  /** Table id */
  tableId: number;
  /** Show search if there are more than x table items. Defaults to -1. */
  showSearchIfMoreThan?: number;
  /** Limit for number of tag checkboxes. Switches to multi select if exceeded. */
  tagCheckboxLimit?: number;
}

/** Selection table with form handling and validation */
function FormTableBase<TData extends TaggedTableData>({
  apiUrl,
  tableItems,
  tableConfig,
  fieldName,
  control,
  selectionVariant = "SINGLE",
  tableId,
  showSearchIfMoreThan = -1,
  tagCheckboxLimit = 3,
  renderState,
  selectionCallback,
  ...props
}: FormTableProps<TData> & WithFormControllerProps): ReactElement {
  const infinitySearch = tableConfig.tableType === "Infinity";
  /* FormTable only supports a single TableOption. It uses the first
   * TableOption included in the TableConfig */
  const tableOption = tableConfig?.optionsConfig[0];
  const entityType = tableOption.value;
  const columns = tableOption.resultConfig.displayedFields;
  const tableOptionId = tableOption.tableOptionId;
  const searchOnLoad = tableConfig.searchOnLoad;
  const uniqueKey = tableOption.uniqueKey;
  // If apiUrl is not provided, use the apiUrl from the table configuration
  const params = useParams<Record<string, any>>();
  const _apiUrl = formatEntityUrl(params, apiUrl ?? tableOption.apiUrl);

  const { data: fieldConfigs } = useFetchFieldConfig(
    entityType,
    undefined,
    tableOption?.includeChildFields ?? false
  );
  const _fieldConfigs = fieldConfigs === undefined ? [] : fieldConfigs;

  const hasSearchedOnLoadRef = useRef<boolean>(false);

  const { t } = useTranslation(["common", "search"]);
  const {
    fieldState: { error },
    setExtendedRules,
  } = renderState;
  const formContext = useFormContext();
  const { getEntityTitle } = useEntityTitle();
  const searchFieldName = `table_search_${fieldName}`;

  // The value in the Search field (used for local search)
  const search: string = formContext.watch(searchFieldName, "");

  /* infinitySearchFieldName, searchIdFieldName and searchFilterStatesFieldName
   * are virtual input fields. They do not exist in the UI as form fields,
   * but they are declared so that values can be stored in the Form Context */
  const infinitySearchFieldName = `table_infinitySearch_${fieldName}`;

  /* The value in the search field used for the infinity search. It is synchronized
   ** with the value of search. Not sure why we need a separate variable for this!? */
  const infinitySearchValue: string =
    formContext.watch(infinitySearchFieldName) ?? "";
  const searchFilterStatesFieldName = `table_filter_states_${fieldName}`;
  const formFilterStates = formContext.watch(searchFilterStatesFieldName);
  const searchIdFieldName = `table_searchId_${fieldName}`;
  const searchId: string = formContext.watch(searchIdFieldName);
  const tagsFieldName = `table_tags_${fieldName}`;
  const selectedTags: string[] = formContext.watch(tagsFieldName, []);
  const [selectedEntities, setSelectedEntities] = useState<TData[]>([]);
  const [selectedItems, setSelectedItems] = useState<TData[]>([]);

  const setSelectedTags = (newTags: string[]) =>
    formContext.setValue(tagsFieldName, newTags);

  /* Function that is triggered when user clicks Enter in the Search field,
   * or clicks on the Search button next to the Search field */
  const onInfinitySearch = () => {
    formContext.setValue(
      infinitySearchFieldName,
      searchOnLoad && infinitySearch && !hasSearchedOnLoadRef.current && !search
        ? "*"
        : search
    );
    formContext.setValue(searchIdFieldName, uuidV4());
    formContext.setValue(searchFilterStatesFieldName, filterStates);
    onSearch(filterStates);
  };

  const {
    filteredItems,
    tags,
    toggleTag,
    isSelectedTag,
    isFetching,
    totalCount,
    onSortChange,
    setPage,
    setPageSize,
    filterFields,
    filterStates,
    getOnChange,
    resetFilterPanel,
    activeFilterState,
    onSearch,
  } = useTableSearch<TData>({
    columns,
    items: tableItems,
    fieldConfigs: _fieldConfigs,
    setSelectedTags,
    selectedTags,
    search,
    apiUrl: _apiUrl,
    entityType,
    tableOptionId,
    infinitySearch,
    infinitySearchValue,
    searchId,
    initialFilterStates: formFilterStates,
  });

  function onReset(): void {
    // Clear the filter states in the form context
    formContext.setValue(searchFilterStatesFieldName, {});
    // Reset the filter states in the SearchFilterPanel
    resetFilterPanel();
  }

  const { createColumnContent } = useTableColumnContent(
    entityType,
    _fieldConfigs
  );

  useEffect(() => {
    const entities = formContext.watch(fieldName, []);
    setSelectedEntities(entities);
  }, [formContext, fieldName]);

  useEffect(() => {
    const selected: Array<TData | number | string> = selectedEntities ?? [];
    if (!isEqual(selected, selectedItems) && !isFetching) {
      const selectedItems = (
        ["number", "string"].includes(typeof selected[0])
          ? selected.map((selectedId) =>
              filteredItems.find((item) => item[uniqueKey] === selectedId)
            )
          : selected
      ) as TData[];
      setSelectedItems((items) =>
        isEqual(items, selectedItems) ? items : selectedItems
      );
    }
  }, [selectedEntities, selectedItems, filteredItems, uniqueKey, isFetching]);

  useEffect(() => {
    setExtendedRules({
      required: false,
      validate: {
        notEmptyRequiredValue: (value) => {
          if (value === undefined) {
            return props.required === false;
          } else if (
            props.required &&
            Array.isArray(value) &&
            value.length === 0
          ) {
            return t("common:forms.tableSelectionIsMandatory");
          } else {
            return true;
          }
        },
      },
    });
  }, [setExtendedRules, t, props.required]);

  const searchField = (
    <>
      <FormTextField
        control={control}
        defaultValue={infinitySearch && searchOnLoad ? "*" : ""}
        fieldName={searchFieldName}
        placeholder={t("search:quickSearch.searchPlaceholder", {
          searchType: getEntityTitle(entityType, 0),
        })}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onInfinitySearch(); // In local search mode, the search is triggered as soon as the user presses a key
          }
        }}
        InputProps={{ endAdornment: !infinitySearch && <Search /> }} // In local search mode a Search icon is displayed inside of the FormTextField, at the right side.
        data-cy="formSearchField"
        sx={{ minWidth: 320 }}
      />
    </>
  );

  const searchButtonAndTags = (
    <>
      {infinitySearch && (
        <Button
          color="primary"
          variant="contained"
          aria-label={t("common:buttons.search")}
          onClick={onInfinitySearch} // In Infinity search mode, the search is triggered when the user presses the Search button
          data-cy="formSearchButton"
        >
          <Search />
        </Button>
      )}
      {tags.length > tagCheckboxLimit ? (
        <FormMultipleSelect
          control={control}
          fieldName={tagsFieldName}
          label={t("search:selectTags")}
          defaultValue={[]}
          dataList={tags.map((tag) => ({ label: tag, value: tag }))}
          FormControlProps={{
            sx: { maxWidth: 320, minWidth: 150, flex: 0 },
          }}
        />
      ) : (
        tags.map((tag) => (
          <FormControlLabel
            key={tag}
            control={<Checkbox checked={isSelectedTag(tag)} />}
            onClick={() => toggleTag(tag)}
            label={tag}
            sx={{ "&:first-of-type": { marginLeft: 0.5 } }}
          />
        ))
      )}
    </>
  );

  const showFilterPanel =
    tableConfig?.searchEnabled &&
    infinitySearch &&
    tableOption?.hasFilters &&
    filterFields.length > 0;
  const showOnlySearchField =
    (totalCount > showSearchIfMoreThan && !infinitySearch) ||
    (tableConfig?.searchEnabled &&
      infinitySearch &&
      (!tableOption?.hasFilters || filterFields.length === 0));

  useEffect(() => {
    if (searchOnLoad && infinitySearch && !hasSearchedOnLoadRef.current) {
      onInfinitySearch();
      hasSearchedOnLoadRef.current = true;
    }
  }, [searchOnLoad, infinitySearch, filterStates, onInfinitySearch]);

  /**
   * Handle selectionCallback for updating selection with debouncing.
   *
   * @param selectedItems - The array of selected items to update.
   */
  const handleSelectionCallback = (selectedItems: TData[]) => {
    selectionCallback?.(selectedItems);
    debouncedSetValue(selectedItems);
  };

  /**
   * Memoized debounced function for setting form context value.
   * Even with '0' set for the debounce, it helps with performance.
   *
   * @param selectedItems - The array of selected items to set in the form context.
   */
  const debouncedSetValue = useMemo(
    () =>
      debounce((selectedItems: TData[]) => {
        formContext.setValue(fieldName, selectedItems, {
          shouldTouch: true,
          shouldValidate: true,
        });
      }, 0),
    [formContext, fieldName]
  );

  return (
    <Box
      display="grid"
      gridTemplateRows="min-content minmax(0, 1fr) 24px"
      height="100%"
      maxHeight="100%"
      overflow="hidden"
      gap={0.5}
      data-cy="formTable"
    >
      <Box
        pt={0.8}
        display="flex"
        gap={0.5}
        alignItems="center"
        flexWrap="wrap"
      >
        {showFilterPanel && (
          <SearchFilterPanel
            displayFilter={
              formFilterStates && Object.keys(formFilterStates).length > 0
            }
            filterStates={filterStates}
            filterFields={filterFields}
            getOnChange={getOnChange}
            resetFilterPanel={onReset}
            searchField={tableOption?.showSearchField ? searchField : undefined}
            triggerSearch={onInfinitySearch}
            activeSearchValue={search}
            activeFilters={activeFilterState}
            showActiveSearchFilters={tableOption.showActiveSearchFilters}
            searchDisabled={!search}
          />
        )}
        {showOnlySearchField && searchField}
        {showOnlySearchField && searchButtonAndTags}
        <WebSetupToolbar entityType={EntityType.WEB_TABLE} entityId={tableId} />
        <WebSetupToolbar
          entityType={EntityType.WEB_TABLE_OPTION}
          entityId={tableOptionId}
        />
      </Box>
      <BilliantTable
        columns={columns.map(
          ({
            column,
            alignment = "left",
            padding = "normal",
            minWidth,
            width,
            maxWidth,
            ...cellSettings
          }) => {
            const cellSize = {
              minWidth,
              width,
              maxWidth,
            };
            return {
              ...cellSettings,
              alignment,
              padding,
              ...cellSize,
              ColumnCell: (() => {
                const columnContent = createColumnContent(column, entityType);
                return createDBTableCellWithoutOnClick(
                  columnContent,
                  alignment,
                  padding,
                  column.type,
                  cellSize
                );
              })(),
            };
          }
        )}
        tableItems={filteredItems}
        selectionVariant={selectionVariant}
        pageTableItems={false}
        selectedItems={selectedEntities}
        selectionCallback={handleSelectionCallback}
        defaultOrderBy={tableOption.resultConfig.defaultOrderBy}
        defaultAscending={tableOption.resultConfig.defaultAscending}
        addWidgetStyle
        disablePagination={!infinitySearch}
        totalItemCount={totalCount}
        tableSettingsKey={String(tableOptionId)}
        isLoading={isFetching || (!_apiUrl && !tableItems?.length)}
        onSortChange={onSortChange}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        {...props}
      />
      <Box display="grid" gridAutoFlow="column">
        {error && <FormHelperText error>{error.message}</FormHelperText>}
        <Box display="grid">
          {(totalCount > showSearchIfMoreThan || infinitySearch) && (
            <Typography
              data-cy="resultCounter"
              variant="body2"
              alignSelf="center"
              justifySelf="end"
            >
              {t("search:showingCountOf", {
                showCount: filteredItems.length,
                totalCount,
              })}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export const FormTable = withFormController(FormTableBase);
