import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { Button, DialogActions } from "common/components";
import { useTranslation } from "react-i18next";

/**
 *  Custom Action bar component used for DatePickers
 */
export function DatePickerActionBar({ onSetToday }: PickersActionBarProps) {
  const { t } = useTranslation(["common"]);
  return (
    <DialogActions
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 225,
        padding: 1,
      }}
    >
      <Button onClick={onSetToday} data-cy="todayButton">
        {t("common:searchFilter.today")}
      </Button>
    </DialogActions>
  );
}
