import { BookmarkEntryType, EntityData, EntityType } from "core/api";
import { DateGroup } from "core/api/enums/DateGroup";
import { Bookmark } from "core/api/models/Bookmark";
import { getCurrentCustomerGroupId } from "core/auth/util";
import dayjs from "dayjs";
import { sortByPrimary, sortEntityType } from "./EntityUtils";

export function createBookmark(
  entityType: EntityType,
  entityId: string,
  entity: EntityData,
  bookmarks?: Bookmark[]
) {
  const current = bookmarks
    ? bookmarks.filter(
        (b) =>
          b.bookmarkEntry.entityType === entityType &&
          b.bookmarkEntry.entityId === entityId
      )
    : [];
  return current.length > 0
    ? current[0]
    : createNewBookmark(entityType, entity);
}

function createNewBookmark(entityType: EntityType, entity: EntityData) {
  const bk = createEmptyBookmark();
  bk.bookmarkEntry.entityType = entityType;
  bk.bookmarkEntry.entityId = entity.entityId;

  return bk;
}

/**
 * @returns Returns an empty bookmark object
 */
export function createEmptyBookmark(): Bookmark {
  return {
    primaryText: "",
    secondaryText: "",
    captionText: "",
    rightText: "",
    url: "",
    bookmarkEntry: {
      customerGroupId: getCurrentCustomerGroupId() ?? -1,
      entryType: BookmarkEntryType.BOOKMARK,
      version: -1,
      userName: "",
      entityType: EntityType.NONE,
      id: "",
      entityId: "-1",
    },
  };
}

/**
 * @returns Returns a copy of a recently viewed item as a bookmark.
 */
export function convertRecentlyViewed(bk: Bookmark): Bookmark {
  return {
    primaryText: bk.primaryText,
    secondaryText: bk.secondaryText,
    captionText: bk.captionText,
    rightText: bk.rightText,
    url: bk.url,
    bookmarkEntry: {
      customerGroupId: bk.bookmarkEntry.customerGroupId,
      entryType: BookmarkEntryType.BOOKMARK,
      version: -1,
      userName: bk.bookmarkEntry.userName,
      entityType: bk.bookmarkEntry.entityType,
      id: "",
      entityId: bk.bookmarkEntry.entityId,
    },
  };
}

export interface GroupedBookmarks {
  /** Entity type */
  entityType: EntityType;
  /** Array of bookmarks entry */
  bookmarks: Bookmark[];
}

/**
 * Sorts and formats bookmarks into groups by entity type
 * @param bookmarks An array of bookmarks
 * @returns Returns bookmarks groups sorted and filtered by entity type
 */
export function sortBookmarksByType(bookmarks: Bookmark[]): GroupedBookmarks[] {
  const bookmarkEntities = Array.from(
    new Set(bookmarks.map(({ bookmarkEntry }) => bookmarkEntry.entityType))
  ).sort(sortEntityType);

  return bookmarkEntities.map((entityType) => ({
    entityType,
    bookmarks: bookmarks
      .filter((bookmark) => bookmark.bookmarkEntry.entityType === entityType)
      .sort(sortByPrimary),
  }));
}

/**
 * Categorises bookmarks into groups based on their date
 * @param bookmarks An array of bookmarks to be categorised.
 * @returns Returns a Record that maps each DateGroup to an array of
 *   Bookmark objects that have their date within that DateGroup.
 *   Each bookmark in the input array is mapped to only one DateGroup.
 */
export function categoriseBookmarksByDate(
  bookmarks: Bookmark[]
): Record<DateGroup, Bookmark[]> {
  const bookmarkGroups: Record<DateGroup, Bookmark[]> = {
    [DateGroup.TODAY]: [],
    [DateGroup.LAST_WEEK]: [],
    [DateGroup.LAST_MONTH]: [],
    [DateGroup.EARLIER]: [],
  };

  const addToGroup = (group: DateGroup, bookmark: Bookmark) => {
    bookmarkGroups[group].push(bookmark);
  };

  for (const bookmark of bookmarks) {
    const { date } = bookmark.bookmarkEntry;
    const today = dayjs().hour(0).minute(0).second(0);
    switch (true) {
      case !date:
        // Bookmark has not date => add to the EARLIER category
        addToGroup(DateGroup.EARLIER, bookmark);
        break;
      case today.isSame(date, "day") || today.isBefore(date):
        addToGroup(DateGroup.TODAY, bookmark);
        break;
      case today.subtract(7, "days").isBefore(date):
        addToGroup(DateGroup.LAST_WEEK, bookmark);
        break;
      case today.subtract(30, "days").isBefore(date):
        addToGroup(DateGroup.LAST_MONTH, bookmark);
        break;
      default:
        addToGroup(DateGroup.EARLIER, bookmark);
    }
  }
  return bookmarkGroups;
}

/**
 * Checks if the given entity type is allowed to be bookmarked.
 *
 * @param entityType - The entity type to check.
 * @returns `true` if the entity type is bookmarkable, otherwise `false`.
 *
 */
export function isBookmarkable(entityType: EntityType): boolean {
  const allowedBookmarks: EntityType[] = [
    EntityType.CUSTOMER,
    EntityType.INVOICE_ACCOUNT,
    EntityType.INVOICE,
    EntityType.INVENTORY,
    EntityType.NP_CASE,
    EntityType.ORDER,
    EntityType.PRODUCT,
    EntityType.SUBSCRIPTION,
    EntityType.TASK,
    EntityType.USER,
  ];

  return allowedBookmarks.includes(entityType);
}
