import { WebSetupSaveAsFlowData, WebSetupSaveAsFlowStep } from "core/api";
import { StepDataResolver } from "../models";

/**
 *Resolver for copy options when copying Web Setup.
 */
export const webSetupSaveAsDataResolver: StepDataResolver<
  WebSetupSaveAsFlowStep,
  WebSetupSaveAsFlowData
> = ({ data, fieldValues }): Partial<WebSetupSaveAsFlowData> => {
  return {
    newName: fieldValues.newName ?? data.newName,
    newPath: fieldValues.newPath ?? data.newPath,
    copyChildren: fieldValues.copyChildren ?? data.copyChildren,
    copyTableFieldDefinitions:
      fieldValues.copyTableFieldDefinitions ?? data.copyTableFieldDefinitions,
    copyFlows: fieldValues.copyFlows ?? data.copyFlows,
    copyActions: fieldValues.copyActions ?? data.copyActions,
    copyScreens: fieldValues.copyScreens ?? data.copyScreens,
    copyViews: fieldValues.copyViews ?? data.copyViews,
    copyWidgets: fieldValues.copyWidgets ?? data.copyWidgets,
    copyFlowSteps: fieldValues.copyFlowSteps ?? data.copyFlowSteps,
    copySearchFilters: fieldValues.copySearchFilters ?? data.copySearchFilters,
    copyTables: fieldValues.copyTables ?? data.copyTables,
  };
};
