import { BookmarkBorder } from "common/assets/icons";
import { IconButton, IconButtonProps, Tooltip } from "common/components";
import { createBookmark, isBookmarkable } from "common/utils";
import { BookmarkEntryType, EntityData, EntityType } from "core/api";
import { Bookmark } from "core/api/models/Bookmark";
import { useBookmarks } from "core/hooks";
import { useTranslation } from "i18n";
import { MouseEvent } from "react";
import { StyledBookmarkIcon } from "./BookmarkIconButton.styles";

interface BookmarkIconButtonProps extends IconButtonProps {
  filterEntityType?: EntityType;
  filterEntryType?: BookmarkEntryType;
  recentlyViewed?: boolean;
  /**
   * Indicates whether the target component is the search results tab of the quick search.
   */
  searchResults?: boolean;
  bookmark: Bookmark;
}
/**
 *
 * @param bookmarkEntry A prefilled bookmark entry
 * @returns An starred/unstarred icon with tooltip. If the entityType is not allowed to be bookmarked it returns null.
 */
export function BookmarkIconButton({
  filterEntityType,
  filterEntryType,
  recentlyViewed,
  searchResults,
  bookmark,
  ...props
}: Readonly<BookmarkIconButtonProps>) {
  if (!isBookmarkable(bookmark.bookmarkEntry.entityType)) {
    return null;
  }

  const { t } = useTranslation(["common"]);
  const { updateStatus, isBookmarked, addBookmark, removeBookmark } =
    useBookmarks(
      filterEntityType ?? undefined,
      filterEntryType ?? BookmarkEntryType.BOOKMARK
    );
  let isEntityBookmark = isBookmarked(bookmark.url);

  const label = isEntityBookmark
    ? t("common:bookmarks.removeFromBookmarks")
    : t("common:bookmarks.addToBookmarks");

  const Icon = isEntityBookmark ? StyledBookmarkIcon : BookmarkBorder;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (updateStatus !== "loading") {
      try {
        isEntityBookmark
          ? removeBookmark(bookmark, true, recentlyViewed, searchResults)
          : addBookmark(bookmark, true, recentlyViewed);

        event.preventDefault();
        event.stopPropagation();
      } finally {
        isEntityBookmark = !isEntityBookmark;
      }
    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  if (
    bookmark.primaryText ||
    (bookmark.bookmarkEntry?.entityId &&
      bookmark.bookmarkEntry.entityId !== "-1")
  ) {
    return (
      <IconButton
        disabled={updateStatus === "loading"}
        onClick={handleClick}
        data-cy="bookmarkIconButton"
        {...props}
        aria-label={label}
      >
        <Tooltip key={label} title={label}>
          <Icon data-active={isEntityBookmark} fontSize={props.size} />
        </Tooltip>
      </IconButton>
    );
  }
  return null;
}

export interface BookmarkIconProps {
  /** Entity Type */
  entityType: EntityType;
  /** Entity data */
  entityData: EntityData;
  /** Optional function to get the entity's URL from the entity's data. */
  getEntityUrl?: (entity: EntityData) => string | undefined;
  /**
   * Set to true only if the bookmark is to be displayed in the search results tab of the quick search.
   * Defaults to false.
   */
  searchResults?: boolean;
}

export function BookmarkIcon({
  entityType,
  entityData,
  getEntityUrl,
  searchResults = false,
}: BookmarkIconProps) {
  const { bookmarks } = useBookmarks(undefined, BookmarkEntryType.BOOKMARK);

  const bookmark = createBookmark(
    entityType,
    entityData.entityId,
    entityData as any,
    bookmarks
  );

  const functionUrl = getEntityUrl ? getEntityUrl(entityData) : undefined;
  return (
    <BookmarkIconButton
      filterEntityType={entityType}
      bookmark={{
        ...bookmark,
        url: functionUrl ?? bookmark.url,
      }}
      size="small"
      searchResults={searchResults}
    />
  );
}
