import {
  Box,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "common/components";
import { formatDataCy } from "common/utils";
import { Icon } from "core/api";
import { useTranslation } from "i18n";
import { ForwardedRef, ReactElement, cloneElement, forwardRef } from "react";
import { FontIcon, getFontIconColor } from "../../../assets";

export interface SelectOption {
  value: string | number | null;
  label: string;
  icon?: Icon;
  dataCy?: string;
}
export interface UnlabeledSelectProps<
  TOption extends SelectOption = SelectOption
> extends SelectProps {
  dataList?: TOption[];
  includeEmptyOption?: boolean;
  renderOption?(option: TOption, selectedValue: unknown): ReactElement;
  fullWidth?: boolean;
  value?: string | number;
  onChange?: (event: SelectChangeEvent<TOption>) => void;
}

/** Labeled select field */
export const UnlabeledSelect = forwardRef(
  <TOption extends SelectOption = SelectOption>(
    {
      children,
      dataList,
      includeEmptyOption,
      renderOption = ({ value, label, icon }, selectedValue) => (
        <MenuItem value={value}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {icon && (
              <ListItemIcon>
                {
                  <FontIcon
                    icon={icon.name}
                    sx={{ color: getFontIconColor(icon.variant) }}
                  />
                }
              </ListItemIcon>
            )}
            {label}
          </Box>
        </MenuItem>
      ),
      ...props
    }: UnlabeledSelectProps<TOption>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation(["common"]);
    const noValueOption = {
      value: null,
      label: t("common:noValueSelected"),
    } as TOption;

    const options =
      includeEmptyOption && dataList ? [noValueOption, ...dataList] : dataList;

    const renderOptions = (
      options: TOption[] | undefined,
      selectedValue: unknown
    ) => {
      if (!options) {
        return null;
      }
      return options.map((option) => {
        const renderedOption = renderOption(option, selectedValue);
        return cloneElement(renderedOption, {
          key: option.value,
          "data-cy": option.dataCy ?? formatDataCy(option.label),
        });
      });
    };

    return (
      <Select {...props} ref={ref}>
        {children ? children : renderOptions(options, props.value)}
      </Select>
    );
  }
);
