import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { DateAdapter, formatToISODate, isValidDate } from "common/utils";
import { FilterValueState, FilterValueStateDatepicker } from "core/api";
import dayjs from "dayjs";
import { useTranslation } from "i18n";
import { omit } from "lodash";
import { KeyboardEvent, ReactElement, useEffect, useState } from "react";
import { StyledMultipleFieldFilterContainer } from "../SearchFilterFields.styles";

interface DatePickerFilterFieldProps {
  /** The filter's state */
  state: FilterValueStateDatepicker;
  /** Label for the field */
  displayName: string;
  /** Gets filter on change function by filter id */
  onChange(data: FilterValueState): void;
  /** Function for performing a search when pressing enter in the filter field */
  onKeyPress?(event: KeyboardEvent<HTMLDivElement>): void;
}

export function DatePickerFilterField({
  displayName,
  state,
  onChange,
  onKeyPress,
}: DatePickerFilterFieldProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const [innerFromValue, setInnerFromValue] = useState<any>(null);
  const [innerToValue, setInnerToValue] = useState<any>(null);

  const fromValue = state?.minValue || null;
  const toValue = state?.maxValue || null;

  useEffect(() => {
    setInnerFromValue(fromValue);
  }, [fromValue]);

  useEffect(() => {
    setInnerToValue(toValue);
  }, [toValue]);

  const getDateSetter =
    (valueType: "minValue" | "maxValue") =>
    (
      newDate: any,
      context?: PickerChangeHandlerContext<DateValidationError>
    ) => {
      const date: Date = newDate?.["$d"];
      const setter = {
        minValue: setInnerFromValue,
        maxValue: setInnerToValue,
      };
      setter[valueType](newDate);
      if (isValidDate(date)) {
        onChange({
          ...state,
          [valueType]: formatToISODate(date),
        });
      } else if (!context?.validationError) {
        onChange(omit(state, [valueType]));
      }
    };

  const setFromDate = getDateSetter("minValue");

  const setToDate = getDateSetter("maxValue");

  return (
    <StyledMultipleFieldFilterContainer data-cy={`filter-${state.filterId}`}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          label={t("common:searchFilter.textFrom", { text: displayName })}
          value={innerFromValue ? dayjs(innerFromValue) : null}
          onChange={setFromDate}
          format="L"
          slotProps={{
            openPickerButton: { "data-cy": "datePickerButton" } as any,
            textField: {
              sx: { minWidth: "190px" },
              name: "minValue",
              onKeyPress,
              inputProps: {
                "data-cy": "minValue",
              },
            },
          }}
        />
        <DatePicker
          label={t("common:searchFilter.textTo", { text: displayName })}
          value={innerToValue ? dayjs(innerToValue) : null}
          onChange={setToDate}
          format="L"
          slotProps={{
            openPickerButton: { "data-cy": "datePickerButton" } as any,
            textField: {
              sx: { minWidth: "190px" },
              name: "maxValue",
              onKeyPress,
              inputProps: {
                "data-cy": "maxValue",
              },
            },
          }}
        />
      </LocalizationProvider>
    </StyledMultipleFieldFilterContainer>
  );
}
