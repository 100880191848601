import {
  Box,
  Grid2,
  Input,
  Slider,
  SliderProps,
  Typography,
} from "common/components";
import React, { useEffect, useState } from "react";
import {
  FormControlledFieldProps,
  withFormController,
} from "../FormController";

export interface FormSliderProps
  extends Omit<SliderProps, "value" | "onChange" | "label" | "defaultValue">,
    FormControlledFieldProps {
  /** The minimum value for the slider and input */
  minValue: number | undefined;
  /** The maximum value for the slider and input */
  maxValue: number | undefined;
  /** The initial value for the slider and input */
  currentValue: number | undefined;
  /** Optional width of the FormSlider component, default width is 35 % */
  width?: string;
  /** Handler for slider value change */
  onChange: (value: number) => void;
}

/**
 * Form slider input component.
 * This component renders a slider input along with a numeric input field.
 */
export const FormSlider = withFormController<FormSliderProps>(
  ({
    minValue = 0,
    maxValue = 0,
    currentValue = 0,
    renderState,
    onChange,
    width,
  }) => {
    const { isDisabled } = renderState;
    const [value, setValue] = useState(currentValue);

    useEffect(() => {
      if (value < minValue) {
        setValue(minValue);
      } else if (value > maxValue) {
        setValue(maxValue);
      }
    }, [minValue, maxValue, value]);

    useEffect(() => {
      setValue(currentValue);
    }, [currentValue]);

    /**
     * Handle slider value change.
     * @param event The event object.
     * @param newValue The new value of the slider.
     */
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
      setValue(newValue as number);
      onChange(newValue as number);
    };

    /**
     * Handle input value change.
     * @param event The change event of the input field.
     */
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = event.target.value === "" ? 0 : Number(event.target.value);
      if (newValue < minValue) {
        newValue = minValue;
      } else if (newValue > maxValue) {
        newValue = maxValue;
      }
      setValue(newValue);
      onChange(newValue);
    };

    /**
     * Handle blur event on the input field.
     * Ensures that the input value is within the specified range.
     */
    const handleBlur = () => {
      if (value < minValue) {
        setValue(minValue);
      } else if (value > maxValue) {
        setValue(maxValue);
      }
    };

    /**
     * Handle focus event on the input field.
     * Selects the entire input value.
     */
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      event.currentTarget.select();
    };

    return (
      <Box
        sx={{
          width: width ?? "35%",
          margin: "20px 5px 0 20px",
        }}
      >
        <Typography id="input-slider" gutterBottom></Typography>
        <Grid2 container spacing={2} alignItems="center">
          <Grid2 sx={{ flexGrow: 1 }}>
            <Slider
              value={typeof value === "number" ? value : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              valueLabelDisplay="on"
              min={minValue}
              max={maxValue}
              data-cy={"formSlider"}
              disabled={isDisabled}
            />
          </Grid2>
          <Grid2>
            <Input
              style={{ width: "38px" }} // same with as it was in Chrome without setting width. If not added the width was a lot bigger in Firefox.
              value={value}
              fullWidth
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              disabled={isDisabled}
              inputProps={{
                step: 1,
                min: minValue,
                max: maxValue,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
              data-cy={"formSliderInput"}
            />
          </Grid2>
        </Grid2>
      </Box>
    );
  }
);
