export default {
  urlNotFound: "URL not found",
  notFoundOrNotAvailable:
    "The resource you requested is not found or no longer available",
  yourApplications: "Your Applications",
  homePage: "Apps",
  applications: "Your Applications",
  customerCare: "Customer care",
  printerQueue: "Printer queue",
  loading: "Loading...",
  loadingApp: "Loading web application...",
  logout: "Log out",
  switchTo: "Switch to",
  yourChangesAreSaved: "Your changes were saved",
  filters: "Filters",
  configuration: "Configuration",
  rowsperpage: "Rows per page",
  customerGroup: "Customer Group",
  help: "Help",
  notifications: "Notifications",
  dashboard: "Dashboard",
  documentation: "Documentation",
  downloadingDocument: "Downloading document...",
  loadingDocument: "Loading document...",
  noDocumentAvailable:
    "The requested document is either restricted or not available at the moment",
  nextPage: "Next page",
  previousPage: "Previous page",
  downloadingFile: "Downloading {{fileName}}",
  downloadFile: "Download {{fileName}}",
  fileName: "File name",
  about: "About ...",
  version: "Version: {{version}}",
  errorNoIdentityProvider:
    "Could not retrieve any identity provider from the database.",
  name: "Name",
  customerName: "Customer Name",
  status: "Status",
  address: "Address",
  invoices: "Invoices",
  inventories: "Inventories",
  type: "Type",
  products: "Products",
  showHideButton: {
    show: "Expand the component",
    hide: "Collapse the component",
  },
  bookmarks: {
    noFoundBookmarks: "There are no {{entityType}} bookmarks",
    noFoundEntityBookmarks: "There are no bookmarks",
    addToBookmarks: "Add to bookmarks",
    removeFromBookmarks: "Remove from bookmarks",
  },
  entityTypeName: {
    account_one: "Account",
    account_other: "Accounts",
    address_one: "Address",
    address_other: "Addresses",
    adjustment_one: "Adjustment",
    adjustment_other: "Adjustments",
    webApplication_one: "Application",
    webApplication_other: "Applications",
    customer_one: "Customer",
    customer_other: "Customers",
    document_one: "Document",
    document_other: "Documents",
    invoice_one: "Invoice",
    invoice_other: "Invoices",
    inventory_one: "Inventory",
    inventory_other: "Inventories",
    message_one: "Message",
    message_other: "Messages",
    note_one: "Note",
    note_other: "Notes",
    npcase_one: "Porting Case",
    npcase_other: "Porting Cases",
    payment_one: "Payment",
    payment_other: "Payments",
    product_one: "Product",
    product_other: "Products",
    productOffering_one: "Product Offering",
    productOffering_other: "Product Offerings",
    repayment_one: "Repayment",
    repayment_other: "Repayments",
    subscription_one: "Subscription",
    subscription_other: "Subscriptions",
    task_one: "Task",
    task_other: "Tasks",
    tasklist_one: "Tasklist",
    tasklist_other: "Tasklists",
    user_one: "User",
    user_other: "Users",
    nrdbClient_one: "Client",
    nrdbClient_other: "Clients",
    nrdbConfig_one: "Configuration",
    nrdbConfig_other: "Configurations",
    nrdbMessage_one: "Message",
    nrdbMessage_other: "Messages",
    nrdbNumber_one: "Phone Number",
    nrdbNumber_other: "Phone Numbers",
    order_one: "Order",
    order_other: "Orders",
    webWidget_one: "Widget",
    webWidget_other: "Widgets",
    webTable_one: "Table",
    webTable_other: "Tables",
    webTableOption_one: "Table Option",
    webTableOption_other: "Table Options",
    webTableFieldDefinition_one: "Table Field Definition",
    webTableFieldDefinition_other: "Table Field Definitions",
    webView_one: "View",
    webView_other: "Views",
    webScreen_one: "Screen",
    webScreen_other: "Screens",
    webFlow_one: "Flow",
    webFlow_other: "Flows",
    webStep_one: "Step",
    webStep_other: "Steps",
    webAppConfig_one: "App Config",
    webAppConfig_other: "App Configs",
    webNavigationGroup_one: "Navigation Group",
    webNavigationGroup_other: "Navigation Groups",
    webSearchFilter_one: "Search Filter",
    webSearchFilter_other: "Search Filters",
    webAction_one: "Action",
    webAction_other: "Actions",
  },
  dateGroupTitle: {
    today: "Today",
    last_week: "During the last week",
    last_month: "During the last month",
    earlier: "Earlier",
  },
  sideBar: {
    show: "Show",
    hide: "Hide",
    showTooltip: "Show the navigation sidebar",
    hideTooltip: "Hide the navigation sidebar",
  },
  loggedOut: {
    message: "You have been logged out",
    gotoLogin: "Go To Login Page",
  },
  editCustomerNote: {
    noteText: "Note text",
    status: "Customer Mood",
  },
  properties: "Properties",
  noAvailableData: "No available data",
  noValue: "<no value>",
  noValueSelected: "<None>",
  deleteSelected: "Delete selected items?",
  copyFrom: "Copy from",
  buttons: {
    accept: "Accept",
    cancel: "Cancel",
    continue: "Continue",
    clear: "Clear",
    close: "Close",
    copy: "Copy",
    copyFrom: "Copy from {{addressType}} address",
    create: "Create",
    decline: "Decline",
    delete: "Delete",
    dismiss: "Cancel Request",
    download: "Download",
    ok: "Ok",
    print: "Print",
    refreshPageData: "Refresh page data",
    reset: "Reset",
    save: "Save",
    update: "Update",
    search: "Search",
    undo: "Undo",
    moreDetails: "More details",
    showAll: "Show all",
    showAllHiddenAfter: "results for {{search}} in {{entityType}}",
    showCountMore: "Show {{count}} more",
    showPDF: "Show PDF",
    preview: "Preview",
    previewHiddenAfter: "next invoice",
    next: "Next",
    back: "Back",
    finish: "Finish",
    generateGUID: "Generate GUID",
    clearGUID: "Clear GUID",
    generatePINCode: "Generate PIN code",
    clearPINCode: "Clear PIN code",
    toggleVisibility: "Toggle password visibility",
    currentUserAccount: "Account of current user",
    accountMenu: "Account menu",
    applicationSwitcher: "Application switcher",
    backTo: "Back to previous {{entityType}}",
    backToTooltip: "Back to",
  },
  forms: {
    fieldIsMandatory: "Field is mandatory",
    mandatory: "{{label}} (mandatory)",
    minLength: "Field must contain at least {{count}} characters",
    maxLength: "Field must contain {{count}} characters or less",
    lengthBetween:
      "Field must contain between {{minLength}} and {{maxLength}} characters",
    lengthExactly: "The field must contain exactly {{count}} characters",
    pattern: "Field must follow pattern {{pattern}}",
    formatShortSocialSecNo: "QQ 12 34 56 A",
    formatLongSocialSecNo: "QQ 12 34 56 A",
    date: {
      dateTimeError: "Selected date or time is not valid (hint: {{hint}})",
      dateError: "Selected date is not valid (hint: {{hint}})",
      timeError: "Selected time is not valid (hint: {{hint}})",
    },
    tableSelectionIsMandatory: "A row must be selected",
    jsonField: "Field must be proper JSON format",
    translations: {
      hint: "Open Translations",
      title: "Translations",
      contentText: "Enter translations for {{label}}",
    },
    iconSelector: {
      searchIcons: "Search Icons...",
      selectIcon: "Select Icon",
      noIcon: "No Icon",
      openIconSelector: "Open Icon Selector",
      icon: "Icon,",
    },
  },
  table: {
    rowActionMenu: {
      tooltip: "Row Actions for current row",
      ariaLabel: "Actions Menu",
    },
    multiRowActionMenu: {
      tooltip: "Row Actions for selected rows",
      ariaLabel: "Row Actions Menu",
    },
    expandRow: "Expand row",
    defaultError: "An error occurred when loading the table",
  },
  cookieText:
    "Billiant Web requires strictly necessary cookies to allow core website functionality such as user login and account management. The website cannot be used properly without strictly necessary cookies.",
  snackbarMessageOccurredMultipleTimes: "(occurred {{times}} times)",
  fetchingData: "Fetching data",
  keyboardShortcuts: "Keyboard Shortcuts",
  settings: "Settings",
  settingsMenu: {
    settingsMenu: "Settings Menu",
    allowDnD: "Allow drag-and-drop",
  },
  navigation: "Navigation",
  filter: {
    hideFilter: "Hide filter",
    showFilter: "Show filter",
  },
  searchFilter: {
    currency: "Currency",
    selectCurrency: "Select currency",
    textFrom: "{{text}} (from)",
    textTo: "{{text}} (to)",
    fromValue: "From {{value}}",
    toValue: "To {{value}}",
    searchText: "Search text",
    today: "Today",
  },
  ofTotal: "of {{count}}",
  zoomIn: "Zoom in",
  zoomOut: "Zoom out",
  approveOrders: "Approve Order(s)",
  adjustmentDate: "Adjustment Date",
  chargeType: "Charge Type",
  generalLedgerInfo: "General Ledger Information",
  taxBundleInfo: "Tax Bundle Information",
  adjustmentDescription: "Adjustment description",
  adjustmentDescriptionHelper:
    "Select or input a description that will appear on the invoice.",
  decision: "Decision",
  earliestInvoiceDate: "Earliest Invoice Date",
  enableImmediateInvoicing: "Enable for immediate invoicing",
  inputAmountInclVat: "Enter amount including {{vatRate}} VAT",
  amountExclVat: "Amount (excluding {{vatRate}} VAT)",
  amountVat: "Value Added Tax (VAT)",
  amountInclVat: "Amount (including {{vatRate}} VAT)",
  amountsInclTax: "Display/input amounts including tax",
  effectiveDate: "Effective date",
  overrideBinds: "Override Binds/Notice Period",
  reason: "Reason",
  supressFees: "Supress Breach Fees",
  categoryWidget: {
    noCategoriesFound: "No categories found",
    noCategoriesFoundHelper: "This customer does not have any categories",
    missingScreenEntity: "Missing {{entityType}} screen entity",
    missingScreenEntityHelper:
      "The widget could not find any {{entityType}} entity within the screen entities",
  },
  historyWidget: {
    message: "Message",
    oldValue: "Old value",
    newValue: "New value",
    orderId: "Order ID",
    dateLastPost: "Date of last change {{date}}",
  },
  dayOfMonth: "day of month",
  days: "Days",
  amount: "Amount",
  amountWithCurrency: "Amount ({{currencyCode}})",
  current: "Current",
  totalAmount: "Total {{amount}}",
  createdBy: "Created by: {{createdBy}}",
  scoreWidget: {
    score: "Score: {{score}}",
    noScore: "No Score",
  },
  reminderXSent: "Reminder {{number}} Sent",
  titleIsMissing: "Title is missing",
  noChildWidget:
    "No matching widget found. Entity type: {{EntityType}},  entity type ID: {{number}}",
  descriptionIsMissing: "Description is missing",
  noResultsFound: "No results found",
  alerts_one: "{{count}} alert",
  alerts_other: "{{count}} alerts",
  unknownEntity: "Unknown Entity",
  breadcrumb: "Breadcrumb",
  selectAll: "Select all",
  items_one: "{{count}} item",
  items_other: "{{count}} items",
  appNameLogo: "{{appName}} logo",
  yes: "Yes",
  no: "No",
  choose: "Choose",
  identifier: "Identifier",
  score: "Score",
  check: "Check",
  creditScore: "Credit Score",
  creditStatus: "Credit Status",
  creditRatingStatus: {
    notChecked: "Not checked",
    creditOk: "Ok",
    creditWarning: "Warning",
    creditNotOk: "Not ok",
  },
  noTaskEntity: "There is no entity connected to the task",
  productOfferingOptions: {
    availableRenewalOptions:
      "There are {{optionsLength}} renewal options available.",
    noRenewalOptions:
      "No renewal options exists. Product is not active, or has binds.",
    payWithCurrent: "Pay with current {{current}}",
    payWithMethod: "Pay with {{method}}",
    readAndApprove: "I have read and approve the",
    termsAndConditions: "terms and conditions.",
    payAndRenew: "Renew and Pay {{amountToPay}}",
  },
  description: "Description",
  applicablePrices: "Applicable Prices",
  orderList: {
    configError: {
      name: "Web Configuration Error",
      description:
        "OrderListWidget requires a TableConfig containing a single TableOption for EntityType ORDER",
    },
  },
  orderCard: {
    completionDate: "Completed: ",
    orderDate: "Ordered: ",
  },
  subject: "Subject",
  messageText: "Message Text",
  productLifeCycle: {
    periods: {
      bind: {
        label: "Bind Period",
        description:
          "The product cannot be terminated before the Earliest Termination Date, unless breach penalties are applied.",
        before: "BEFORE the Bind Period",
        within: "WITHIN the Bind Period",
        after: "AFTER the Bind Period",
      },
      regret: {
        label: "Regret Period",
        description:
          "The product can be terminated with immediate effect. No breach penalties are applicable.",
        within: "WITHIN the Regret Period",
      },
      notice: {
        label: "Notice Period",
        description: {
          beforeLatestNoticeDate:
            "The product can be terminated at the Bind Period END on the condition that termination is requested BEFORE the Latest Notice Date.",
          withAdjustment:
            "The product can be terminated at the Earliest Termination Date on the condition that termination is requested BEFORE the Latest Notice Date.",
          withoutAdjustment:
            "The product can be terminated at the Earliest Termination Date on the condition that termination is requested TODAY.",
        },
        within: "AFTER the bind period notice date",
      },
    },
    marks: {
      bindStart: "Bind Period START",
      bindEnd: "Bind Period END",
      latestRegret: "Latest Regret Date",
      latestNotice: "Latest Notice Date",
      noticeDateWithinBind: "Bind period notice date",
      earliestTermination: "Earliest Termination Date",
    },
    currentDateIs: "Current date is ",
    today: "TODAY",
  },
  noItemSelected: "No item selected",
  noActionMatchingSelection: "No actions matching the current selection",
  webSetup: {
    newName: "Name",
    newPath: "Path",
    copyChildren: "Duplicate following elements",
    visibility: "Visibility",
  },
} as const;
